.proposals_section {
    width: 100%;
    padding: 100px 0;
    position: relative;
}
.pageTitle{
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 28px;
    font-weight: 600;
    color: #0F1626;
    line-height: 38px;
    margin: 0;
}
.tab_list{
    padding: 0;
    margin-bottom: 0;
    list-style: none;
    width: 100%;
    display: flex;
    align-items: center;
}
.tab_list li{
    padding: 5px 15px 10px;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    color: rgba(0,0,0,0.5);
    font-weight: 500;
    position: relative;
    cursor: pointer;
}

.tab_list li.active{
    color: #0F1626;
    font-weight: 600;
}
.tab_list li::before{
    content: '';
    width: 0;
    height: 2px;
    display: inline-block;
    position: absolute;
    bottom: 0;
    left: 0;
    background-color: #0F1626;
    transition: width 5s all;
}
.tab_list li.active::before{
    width: 100%;
}
/* pagination */
.pagination_wrap {
    padding-top: 1rem;
    text-align: center;
}

.pagination_wrap>ul {
    padding: 0;
    margin: 0;
}

.pagination_wrap li {
    display: inline-block;
    margin: 0 2px;
}

.pagination_wrap li.previous {
    margin: 0 15px 0 0px;
}

.pagination_wrap li.next {
    margin: 0 0px 0 15px;
}

.pagination_wrap li>a {
    padding: 2px 6px;
    margin: 1px;
    width: 32px;
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    min-width: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #0F1626;
}

.pagination_wrap li>a:hover {
    background: #f5f5f5;
}

.pagination_wrap li>a:focus {
    outline: none;
}

.pagination_wrap li.active>a {
    background: #0F1626;
    border-color: #0F1626;
    color: #FFF;
}

.pagination_wrap li.active>a:hover {
    background: #0F1626;
    border-color: #0F1626;
    color: #FFF;
}
/* pagination over */

/* single proposal css */
.bg_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(0 0 0 / 55%);
    z-index: 9995;
    display: none;
}
.bg_overlay.show{
    display: block;
}
.details_wrap{
    width: 100%;
    max-width: calc(100% - 30vw);
    position: fixed;
    right: -100%;
    top: 0;
    bottom: 0;
    z-index: 9996;
    overflow: hidden;
    overflow-y: auto;
    background: #EFEFEF;
    transition: right 0.3s linear;
    /* display: none; */
}
.details_wrap.show{
    right: 0;
    /* right: -100%; */
    /* display: block; */
}
.header_wrap{
    width: 100%;
    display: inline-block;
    position: relative;
    background: #FFF;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    padding: 15px 25px;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
}
.header_wrap .back_wrap{
    background: transparent;
    border: 0;
    padding: 0;
    font-size: 28px;
    color: #0F1626;
}
.details_wrap_body{
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 25px 30px;
}
.wrap_detail{
    width: 100%;
    max-width: calc(100% - 0vw);
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
}
.button_wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 0;
    border-bottom: 1px solid #EFEFEF;
}
.wrap_detail .title_wrap_details{
    padding: 25px 15px;
    border-bottom: 1px solid #EFEFEF;
}
.wrap_detail .title_wrap_details h3{
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
}
.wrap_detail .description_wraps{
    padding: 25px 15px;
    border-bottom: 1px solid #EFEFEF;
}
.wrap_detail .description_wraps p{
    font-size: 15px;
    line-height: 22px;
}
.price_wrap,
.type_project{
    padding: 25px 15px;
    border-bottom: 1px solid #EFEFEF;
}
.price_wrap p{
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: #0F1626;
}
.price_wrap p span{
    display: block;
    opacity: 0.5;
    font-size: 14px;
}
.type_project p{
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: #0F1626;
}
.type_project p span{
    font-weight: 400;
}
.skills_wrap_detail{
    padding: 25px 15px;
}
.skills_wrap_detail h5{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}
.skills_wrap_detail p{
    font-size: 16px;
    font-weight: 500;
    color: #0F1626;
    margin-bottom: 10px;
}
.skills_wrap_detail .budges_skills{
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}
.skills_wrap_detail .budges_skills li{
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 500;
    background: #e2e2e2;
    display: inline-block;
    margin: 0 10px 10px 0px;
    border-radius: 30px;
}
.border_end{
    border-right: 1px solid #EFEFEF;
}
.bid_label{
    margin-bottom: 0;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-left: 15px;
    font-weight: 400;
}
.bid_label span a{
    color: #1a62f5;
    text-decoration: underline;
    font-weight: 600;
}
/* single proposal css over */