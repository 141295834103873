.tabs_list {
    width: 100%;
    padding: 0;
    list-style: none;
    display: flex;
    margin-bottom: 40px;
    border-bottom: 1px solid transparent;
    position: relative;
}

.tabs_list li {
    padding: 0 0 8px;
    text-align: center;
    position: relative;
    margin-right: 20px;
}

.tabs_list li button {
    font-size: 16px;
    font-weight: 500;
    color: #1c2740;
    padding: 0 10px;
    font-family: 'Noto Sans JP', sans-serif;
    background-color: transparent;
    border: 0;
    box-shadow: unset;
}

.tabs_list li.active button {
    color: var(--cyberYellow);
    /* color: #1a62f5; */
}

.tabs_list li.active button:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    /* background: #1a62f5; */
    background: var(--cyberYellow);
    width: 100%;
    height: 2px;
}
.terms{
    font-size: 14px;
    color: #646464;
    letter-spacing: .31px;
    margin-top: 28px;
    margin-bottom: 1rem;
}
.number-with-image{
    position: relative;
}
.number-with-image .icon{
    position: absolute;
    top: 50%;
    left: 12px;
    transform: translate(0, -50%);
}
.card-history{
    width: 100%;
}
.card-history .card-detail{
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    padding: 20px 20px 12px;
}
.card-history .card-detail{
    border: 1px solid #ededed;
    margin-bottom: 20px;
    
}
.card-detail.payment-method {
    border: 1px solid #ededed;
    padding: 20px 20px 20px;
    align-items: flex-start;
    justify-content: space-between;
}
.card-detail .checkbox{
    width: 40px;
    display: inline-block;
    vertical-align: top;
}
.card-detail .checkbox span{
    display: block;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 3px solid #c8c8c8;
}
.card-detail.active .checkbox span{
    border: 6px solid #1a62f5;
}
.card-detail .cards{
    display: inline-block;
    width: calc(100% - 40px);
}
.card-history .card-detail span {
    font-size: 16px;
    font-weight: 500;
}