.header_wrap {
    width: 100%;
    /* height: 60px; */
    display: inline-block;
    position: relative;
}

.navbar_wrap {
    width: 100%;
    height: 60px;
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.navbar_brand {
    font-size: 33px;
    color: #000;
    line-height: 1.5px;
    font-weight: 800;
}

.navbar_brand img {
    /* width: 100%; */
    height: 25px;
}
.client_header{
    padding: 0 1.375rem;
}
.user_img {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: relative;
    border-radius: 25px;
    overflow: hidden;
    margin-right: 5px;
}

.user_img.character {
    display: flex;
    align-items: center;
    justify-content: center;
    background: #d6d9dc;
    font-size: 18px;
    color: #000;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 500;
}

.user_img img {
    width: 100%;
    height: 100%;
    object-fit: fill;
}

.dropdown_li a{
    cursor: pointer;
}
/* .dropdown_menu {
    position: absolute;
    z-index: 1000;
    /* display: none; *
    min-width: 10rem;
    padding: .5rem 0;
    margin: 0;
    font-size: 1rem;
    color: #212529;
    text-align: left;
    list-style: none;
    background-color: #fff;
    background-clip: padding-box;
    border-radius: .25rem;
    -webkit-box-shadow: 0 3px 5px rgb(0 0 0 / 4%), 0 4px 8px rgb(0 0 0 / 16%);
    box-shadow: 0 3px 5px rgb(0 0 0 / 4%), 0 4px 8px rgb(0 0 0 / 16%);
} */
.dropdown_li:hover .dropdown_menu{
    display: block;
}
/* .dropdown_li a{
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    color: rgba(0,0,0,.55);
}
.dropdown_menu li{
    list-style: none;
    width: 100%;
}*/
.dropdown_menu li:hover{
    color: #1e2125;
    background-color: #e9ecef;
}
.alert_notification{
    position: absolute;
    top: 15px;
    right: 7px;
    padding: 0.25rem;
    border-radius: 50%;
    border:1px solid #FFF;
}
@media screen and (max-width:576px) {
    .mobile-dropdown .dropdown-menu{
        box-shadow: none !important;
        border:0;
        background: transparent;
        padding-left: 15px;
    }
}