.proposal_section{
    width: 100%;
    padding: 50px 0;
}
.budges_skills{
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}
.budges_skills li{
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 500;
    background: #e2e2e2;
    display: inline-block;
    margin: 0 10px 10px 0px;
    border-radius: 30px;
}
.file_input_wrap{
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 20px 0;
}
.file_input_wrap input[type="file"]{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
}
.file_input_wrap .file_label3{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:2px dashed #ced4da;
    border-radius: .25rem;
    /* opacity: 0.5; */
    font-size: 18px;
    color: #0F1626;
    margin-bottom: 0;
    cursor: pointer;
}
.small_text{
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
    font-size: 13px;
    margin-top: 5px;
}

.file_name {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.file_name li {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.file_name li:last-child {
    margin-bottom: 0;
}

.file_name li span {
    color: #000;
    letter-spacing: .24px;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    font-weight: 600;
    word-break: break-all;
}

.file_name li .attchment_icon {
    /* color: #000; */
    font-size: 22px;
    margin-right: 10px;
}

.file_name li .view_filebtn {
    background: #f2f2f2;
    width: 30px;
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
}
.file_name li .view_filebtn.btn_downlo {
    background: transparent;
    border: 0;
    padding: 0;
}