@import url('https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@100;300;400;500;700;900&family=Roboto:wght@100;300;400;500;700;900&family=Montserrat:wght@100;200;300;400;500;600;700;800;900&display=swap');
@import url('./font.css');
:root {
  --font-sf-pro-text: 'Metropolis', Arial, sans-serif;
  /* --font-sf-pro-text: 'Montserrat', sans-serif; */
  /* --font-sf-pro-text: 'SF Pro Text', sans-serif; */
  --font-roboto: 'Roboto', sans-serif;
  --color-blue: #072AC8;
  --color-blue-hover-01: rgba(7, 42, 200, 0.8);
  --color-blue-hover-02: rgba(7, 42, 200, 0.05);
  --color-gray-01: #65735b;
  --color-gray-02: #9aaa97;
  --color-white: #FFFFFF;
  --blue: #072AC8;
  --black: #000;
  --richBlack: #65735b;
  /* --richBlack: #006388; */
  --midnight: #25313c;
  /* --richBlack: #0F1626; */
  --richBlackLight: rgb(0 99 136/85%);
  /* --richBlackLight: #1c2740; */
  --queenBlue: #52658F;
  --queenBlueHover: #444547;
  --white: #FFF;
  --bodyColor: #F6F7FB;
  /* --bodyColor: #EFEFEF; */
  --colorGreen: #00ae63;
  /* --grayWeb:#828081; */
  --grayWeb: #707070;
  --cyberYellow: #fcaf17;
  /* --cyberYellow: #FFCE00; */
  --errorColor: #cc2c2c;
}

html, body {
  padding: 0;
  margin: 0;
  font-family: var(--font-sf-pro-text), 'Roboto', -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen, Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;
  font-weight: 400;
  /* background: var(--color-white);
  color: var(--color-gray-01); */
}

body {
  /* background: var(--bodyColor); */
  background: var(--color-white);
  color: var(--midnight);
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}

.container {
  max-width: 1200px;
}

.btn-block {
  width: 100%;
}

.btn {
  font-family: var(--font-sf-pro-text);
  /* font-family: 'Noto Sans JP', sans-serif; */
}

.form-control {
  font-family: var(--font-sf-pro-text);
  /* font-family: 'Roboto', sans-serif; */
  padding: .375rem .75rem;
}

.pl-0 {
  padding-left: 0;
}

.pr-0 {
  padding-right: 0;
}

.form-control.error {
  border-color: var(--errorColor);
}

.error-text {
  font-size: 14px;
  font-family: var(--font-sf-pro-text);
  /* font-family: 'Noto Sans JP', sans-serif; */
  font-weight: 400;
  color: var(--errorColor);
}

/* .cniYFZ,.jUxVQN, .SNVCN, .PyiZR{
  display: inline-block !important;
} */

.client_header {
  padding: 0 .375rem;
}

.client_header .nav-item.dropdown .dropdown-toggle.nav-link {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.client_header .navbar-nav .nav-link {
  font-family: var(--font-sf-pro-text);
  /* font-family: 'Noto Sans JP', sans-serif; */
  font-weight: 500;
  letter-spacing: 0.1px;
  color: var(--color-gray-01);
  /* color: #65735b; */
  padding-left: 0.9rem;
  padding-right: 0.9rem;
}

.client_header .navbar-nav .nav-link:hover {
  color: var(--color-blue);
  /* color: #072AC8; */
}

.client_header .navbar-nav .nav-link.active {
  color: var(--color-blue);
  /* color: #072AC8; */
  /* color: #0F1626; */
  font-weight: 600;
}

.client_header .navbar-nav .nav-link.btn {
  color: var(--color-white);
  /* color: #FFF; */
  line-height: 17px;
}

.client_header .dropdown-item svg {
  stroke-width: 0;
}

.min-200 {
  min-width: 200px;
}

.max-200 {
  max-width: 200px;
}

.display-block {
  display: block;
}

.ml-auto {
  margin-left: auto;
}

.logo-web img {
  height: 25px;
}

.btn_pJob {
  margin: 3px 15px 0;
  height: 35px;
  width: 100%;
  max-width: 110px;
  background: #fcaf17;
  /* background: var(--cyberYellow); */
  border-radius: 35px;
  text-align: center;
  /* padding: 5px 15px !important; */
  /* padding-left: 15px !important; */
  font-size: 15px;
  /* padding-right: 15px !important; */
  line-height: 1.15;
  color: #FFF !important;
  font-weight: 500;
}

.breadcrumb .breadcrumb-item a {
  font-weight: 600;
  color: #006388;
}

.breadcrumb .breadcrumb-item.active a {
  font-weight: 400;
  color: #25313c;
}

.profile_banner {
  width: 100%;
}

.profile_banner img {
  width: 100%;
}

.mt_150 {
  margin-top: -150px;
}

.marT_56 {
  margin-top: 56px;
}

.mt_65 {
  margin-top: -65px;
}

.rc-steps-item-title {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px !important;
  margin-bottom: 0 !important;
}

.rc-steps-item-finish .rc-steps-item-icon {
  /* background-color: var(--richBlack) !important; */
  background-color: var(--colorGreen) !important;
  border-color: var(--colorGreen) !important;
}

.rc-steps-item-finish .rc-steps-item-content .rc-steps-item-title {
  color: #0F1626;
}

.rc-steps-item-process .rc-steps-item-icon {
  background-color: var(--cyberYellow) !important;
  border-color: var(--cyberYellow) !important;
}

.rc-steps-item-finish .rc-steps-item-icon .rc-steps-icon {
  color: #fff !important;
}

.rc-steps-item-finish .rc-steps-item-tail:after {
  background-color: var(--richBlack);
}

/* .rc-steps-label-vertical .rc-steps-item-tail{
  margin-left: 70px !important;
}
.rc-steps-label-vertical .rc-steps-item-icon {
  margin-left: 58px !important;
}
.rc-steps-label-vertical .rc-steps-item-content{
  width: 150px !important;
  max-width: 150px;
} */

.conatiner_home {
  min-height: 100vh;
  padding: 0 0.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100vh;
}

/* agent_steps css */

.agent_steps .rc-steps .rc-steps-item-active .rc-steps-item-icon {
  background: var(--blue) !important;
  border-color: var(--blue) !important;
}

.agent_steps .rc-steps .rc-steps-item .rc-steps-item-tail {
  display: none;
}

.agent_steps .rc-steps .rc-steps-item .rc-steps-item-content {
  margin-top: 0;
  font-family: 'Noto Sans JP', sans-serif;
}

/* agent_steps css over */

input[type="number"]::-webkit-outer-spin-button, input[type="number"]::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */

input[type="number"] {
  -moz-appearance: textfield;
}

input[type="number"].number_input::-webkit-outer-spin-button, input[type="number"].number_input::-webkit-inner-spin-button {
  -webkit-appearance: inner-spin-button;
}

input[type="number"].number_input {
  -moz-appearance: unset !important;
}

.label_control {
  font-family: 'Noto Sans JP', sans-serif;
  color: #000;
  font-weight: 700;
  font-size: 16px;
  margin: 10px 0;
}

.user_pro .dropdown-menu {
  right: 0;
}

.user_pro .dropdown-toggle {
  padding: 0 .5rem;
}

.btn-primary {
  background-color: #072AC8;
  border-color: #072AC8;
  /* background-color: #fcaf17; */
  /* border-color: #fcaf17; */
  /* background-color: var(--richBlack);
  border-color: var(--richBlack); */
  color: #FFF;
  font-weight: 600;
  padding: .5rem .85rem;
  font-size: .9rem;
  line-height: 1.4;
}

.btn-primary:hover, .btn-primary:focus {
  background-color: rgba(7, 42, 200, 0.8);
  border-color: rgba(7, 42, 200, 0.8);
  /* background-color: rgba(252 175 23/85%);
  border-color: rgba(252 175 23/85%); */
  /* background-color: var(--richBlackLight);
  border-color: var(--richBlackLight); */
  outline: 0;
  box-shadow: none;
}

.mr-15 {
  margin-right: 15px;
}

.btn-outline-primary {
  border-color: #072AC8;
  color: #072AC8;
  /* border-color: #fcaf17;
  color: #fcaf17; */
  font-weight: 600;
  padding: .5rem .85rem;
  font-size: .9rem;
  line-height: 1.4;
}

.btn-outline-primary:hover, .btn-outline-primary:focus {
  background-color: rgba(7, 42, 200, 0.05);
  border-color: #072AC8;
  color: #072AC8;
  /* background-color: rgba(252, 175, 23, 0.05); */
  /* border-color: #fcaf17;
  color: #fcaf17; */
  /* background-color: var(--richBlackLight);
  border-color: var(--richBlackLight); */
  outline: 0;
  box-shadow: none;
}

.bg-light {
  background-color: #fff !important;
  /* background-color: var(--bodyColor); */
}

.contract-table tbody tr td {
  padding: 20px 30px;
}
.contract-table tbody tr:last-child td{
  border-bottom: 0;
}

.profile-user{
  width: 45px;
  height: 45px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
}
.profile-user img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.profile-user .img-shape{
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 18px;
  font-weight: 800;
  color: #0F1626;
  background: #efefef;
}

.user-wrap-block {
  display: flex;
  align-items: center;
}

.user-wrap-block .image_wrap {
  width: 45px;
  height: 45px;
  border-radius: 50%;
  overflow: hidden;
  margin-right:10px;
}

.user-wrap-block .image_wrap img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.user-wrap-block .title {
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 0px;
  line-height: 20px;
  letter-spacing: .4px;
  color: var(--midnight);
  cursor: pointer;
  word-break: break-all;
}

.user-wrap-block .flag_text{
  margin-bottom: 0;
}

/* loader css */

.loader {
  border: 3px solid #f3f3f3;
  /* Light grey */
  border-top: 3px solid transparent;
  /* Blue */
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: block;
  margin: 0 auto;
  animation: spin 0.9s linear infinite;
}

.loader.yellow {
  border: 3px solid #FCAF17;
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: block;
  margin: 0 auto;
  animation: spin 0.9s linear infinite;
}

.loader.black {
  border: 3px solid #000;
  border-top: 3px solid transparent;
  border-radius: 50%;
  width: 25px;
  height: 25px;
  display: block;
  margin: 0 auto;
  animation: spin 0.9s linear infinite;
}

.main-loader {
  border: 3px solid #0F1626;
  /* Light grey */
  border-top: 3px solid transparent;
  /* Blue */
  border-radius: 50%;
  width: 50px;
  height: 50px;
  display: block;
  margin: 0 auto;
  animation: spin 0.9s linear infinite;
}

/* loader css over */

/* tab css */

.tabs_ul {
  padding-left: 0;
  list-style: none;
  display: flex;
  margin-bottom: 30px;
  border-bottom: 1px solid #e8e8e8;
  position: relative;
}

.tabs_ul li {
  padding: 0 0 8px;
  text-align: center;
  position: relative;
  background-color: #fff;
}

.tabs_ul li button {
  font-size: 16px;
  font-weight: 400;
  color: #828081;
  padding: 0 10px;
  background-color: transparent;
  border: 0;
}

.tabs_ul li button.active {
  color: #fcaf17;
  font-weight: 600;
}

.tabs_ul li button.active:after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fcaf17;
  width: 100%;
  height: 2px;
}

/* tab css over */

.btn-edit-btn {
  background: #FFF;
  border-radius: 50%;
  width: 30px;
  height: 30px;
  border: 1px solid #9aaa97;
  font-size: 16px;
  color: #9aaa97;
  text-align: center;
  line-height: 0;
  /* font-family: 'Metropolis'; */
  /* display: flex; */
  /* align-items: center;
    justify-content: center; */
  padding: 0;
}

.btn-edit-btn:hover {
  background: #f9f9f9;
}

.list-group-item-hover:hover {
  background: #f9f9f9;
}

.td-border-0 tbody tr td {
  border: 0;
}

.person-client-wrap {
  width: 100%;
  display: flex;
  align-items: center;
}

.person-client-wrap .img_wra {
  width: 35px;
  height: 35px;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.person-client-wrap .img_wra img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.person-client-wrap .name-wra {
  width: calc(100% - 35px);
  font-size: 15px;
}

.rounded-border {
  border-radius: 1.3rem;
}

/* payment animation */

.loading-bar {
  position: relative;
  width: 200px;
  height: 10px;
  margin-top: 50px;
  background-color: #808080;
  border-radius: 10px;
  overflow: hidden;
}

.loading-bar span {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #828081;
  transition: width .5s ease;
}

.profile-wr{
  width: 200px;
  display: flex;
  align-items: center;
}
.profile-wr .ima_wrap{
  width: 35px;
  height: 35px;
  display: inline-block;
  position: relative;
  overflow: hidden;
  border-radius: 50%;
  margin-right: 10px;
}

.small-tabs{
  width: 100%;
  margin: 25px 0 0;
  border-bottom: 1px solid #dee2e6;
  list-style: none;
  padding-left: 0;
  display: flex;
}
.small-tabs li{
  padding: 5px 10px;
  display: inline-block;
  cursor: pointer;
  color: #0F1626;
  position: relative;
  font-size: 15px;
  font-weight: 500;
}
.small-tabs li.active{
  color: #fcaf17;
}
.small-tabs li::after{
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 2px;
  background: #fcaf17;
  display: none;
}
.small-tabs li.active::after{
  display: block;
}
.profile-wr .ima_wrap img{
  width: 100%;
  height: 100%;
  object-fit: cover;
}
.bg-overlay-endContact{
  position: fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background: rgba(0,0,0,0.3);
  z-index: 99999;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: no-drop;
}
@keyframes hideLoading {
  to {
    height: 0;
    margin: 0;
    opacity: 0;
  }
}

.freelancer-table tbody tr td{
  padding: 1rem .5rem;
}

.milestone-based{
  padding: 5px 15px;
  min-width: 100px;
  display: inline-block;
  /* background: rgba(1, 255, 6, 0.2); */
  /* color:#238425 */
}
.milestone-based.success{
  background: rgba(1, 255, 6, 0.2);
  color:#238425
}
.milestone-based.danger{
  background: rgba(255, 1, 1, 0.2);
  color:#842323
  
}
.milestone-based.warning{
  background: rgba(255, 230, 1, 0.2);
  color:#ffe601
}
.milestone-based.bg-warning{
  color: #FFF !important;
}

.checkmark {
  /* position: absolute;
  top: 50%;
  left: 50%; */
  width: 100px;
  height: 100px;
  /* margin-top: -50px;
  margin-left: -50px; */
  display: block;
  margin: 0 auto;
  border-radius: 50%;
  stroke-width: 1px;
  stroke: #fff;
  stroke-miterlimit: 10px;
}

.checkmark-circle {
  fill: none;
  stroke-dasharray: 200px;
  stroke-dashoffset: 200px;
  stroke-width: 1px;
  stroke-miterlimit: 10px;
}

@keyframes fillCheckSuccess {
  to {
    box-shadow: inset 0 0 0 100px #3c763d;
  }
}

@keyframes fillCheckError {
  to {
    box-shadow: inset 0 0 0 100px #dc3545;
  }
}

@keyframes beatCheck {
  0%, 100% {
    transform: scale(1);
  }
  50% {
    transform: scale(1.05);
  }
}

@keyframes echoSuccess {
  from {
    box-shadow: inset 0 0 0 100px #3c763d, 0 0 0 0 #3c763d;
  }
  to {
    box-shadow: inset 0 0 0 100px #3c763d, 0 0 0 50px transparent;
  }
}

@keyframes echoError {
  from {
    box-shadow: inset 0 0 0 100px #dc3545, 0 0 0 0 #dc3545;
  }
  to {
    box-shadow: inset 0 0 0 100px #dc3545, 0 0 0 50px transparent;
  }
}

.checkmark-check {
  stroke: #fff;
  stroke-dasharray: 100px;
  stroke-dashoffset: 100px;
  transform-origin: 50% 50%;
}

@keyframes strokeCheck {
  to {
    stroke-dashoffset: 0px;
  }
}
.table-getpaid tbody tr td{
  padding: 1rem .5rem !important;
}
.processing.complete .checkmark.checkmark-success {
  -webkit-animation: fillCheckSuccess 0.4s cubic-bezier(0.65, 0, 0.45, 1) 1s forwards, beatCheck 0.2s ease-out 1.5s forwards, echoSuccess 1.25s ease-out 1.5s forwards;
  animation: fillCheckSuccess 0.4s cubic-bezier(0.65, 0, 0.45, 1) 1s forwards, beatCheck 0.2s ease-out 1.5s forwards, echoSuccess 1.25s ease-out 1.5s forwards;
}

.processing.complete .checkmark-circle {
  -webkit-animation: strokeCheck 0.5s cubic-bezier(0.65, 0, 0.45, 1) 0.75s forwards;
  animation: strokeCheck 0.5s cubic-bezier(0.65, 0, 0.45, 1) 0.75s forwards;
}

.processing.complete .checkmark.checkmark-success .checkmark-circle {
  stroke: #3c763d;
}

.processing.complete .checkmark-check {
  -webkit-animation: strokeCheck 0.3s cubic-bezier(0.65, 0, 0.45, 1) 1.25s forwards;
  animation: strokeCheck 0.3s cubic-bezier(0.65, 0, 0.45, 1) 1.25s forwards;
}

/* payment animation over */
.cursor-noDrop{
  cursor: no-drop;
}
/* search */

.search_jobs form {
  position: relative;
}

.search_jobs .search_input {
  padding: 8px 40px 8px 20px;
  height: 40px;
  border-radius: 3px;
}

.search_jobs .btn_search {
  background: var(--cyberYellow);
  border-radius: 0 3px 3px 0;
  border: none;
  position: absolute;
  right: 0;
  top: 0;
  height: 40px;
  width: 40px;
  color: #FFF;
  font-size: 23px;
  display: flex;
  align-items: center;
  justify-content: center;
}

/* search over */

/* side-nav-settings */

.side-nav-settings {
  width: 100%;
  display: inline-block;
  position: relative;
  padding: 0;
  margin-bottom: 0;
  list-style: none;
}

.side-nav-settings li {
  position: relative;
  width: 100%;
}

.side-nav-settings li a {
  position: relative;
  width: 100%;
  padding: 10px 15px 10px 20px;
  display: block;
  font-family: 'Metropolis', sans-serif;
  font-size: 16px;
  font-weight: 500;
  text-decoration: none;
  letter-spacing: -0.3px;
}

.side-nav-settings li a:hover {
  text-decoration: underline;
  color: initial;
}

.side-nav-settings li a::before {
  content: '';
  position: absolute;
  left: 0;
  bottom: 0;
  top: 0;
  width: 4px;
  height: 100%;
  background: #072AC8;
  display: none;
}

.side-nav-settings li a.active {
  color: #0F1626;
}

.side-nav-settings li a.active::before {
  display: inline-block;
}

.side-nav-settings li a.active:hover {
  text-decoration: none;
}

/* side-nav-settings over */

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* loader css over */

/* phone input */

.PhoneInput {
  width: 100%;
  padding: .313rem .75rem;
  font-size: 1rem;
  font-weight: 400;
  line-height: 1.5;
  color: #212529;
  background-color: #fff;
  border: 1px solid #ced4da;
  border-radius: .25rem;
}

.PhoneInput .PhoneInputInput {
  border: 0;
}

.PhoneInput .PhoneInputInput:focus {
  outline: 0;
}

/* phone input over */

/* email verify */

.emailVerify_section {
  margin: 56px 0 0;
  padding: 75px 0;
  width: 100%;
  height: calc(100vh - 56px);
  display: flex;
  align-items: center;
  justify-content: center;
}

.emailVerify_wrap {
  width: 100%;
  max-width: 500px;
  box-shadow: 0 0 30px rgb(0 0 0 / 10%);
  padding: 30px;
  width: 100%!important;
  background: #fff;
  border-radius: 8px;
  text-align: center;
}

.emailVerify_wrap h1 {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 30px;
  font-weight: 600;
  color: #000;
  text-align: center;
  margin: 25px 0 35px;
}

.emailVerify_wrap p {
  font-family: 'Noto Sans JP', sans-serif;
  font-size: 16px;
  line-height: 28px;
  font-weight: 400;
  color: rgb(0 0 0 / 75%);
  text-align: center;
  margin: 25px 0 35px;
}

.emailVerify_wrap button {
  padding: 5px 15px;
  width: 100%;
  max-width: 300px;
  min-width: 216px;
  height: 45px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 50px auto 0;
  border: 0;
  border-radius: 5px;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 500;
  letter-spacing: 0.5px;
  background: #efefef;
  color: #000;
}

/* email verify over*/

/* home page css */

.hero_section {
  padding: 60px 0 80px;
  /* width: 100%; */
  /* display: block; */
  /* background: #FFF; */
  position: relative;
}

.banner-headline {
  display: block;
  max-width: 55%;
  z-index: 2;
  position: relative;
}

.banner-headline.trust {
  margin-top: 0px;
  max-width: 100%;
}

.banner-headline h1 {
  font-family: var(--font-sf-pro-text);
  /* font-family: 'Roboto', sans-serif; */
  /* font-family: 'Noto Sans JP', sans-serif; */
  font-weight: 800;
  font-size: 65px;
  line-height: 70px;
  letter-spacing: -0.025em;
  color: #072AC8;
  margin: 0 0 10px;
}

.banner-headline p {
  font-family: var(--font-sf-pro-text);
  /* font-family: 'Roboto', sans-serif; */
  font-size: 24px;
  line-height: 32px;
  letter-spacing: -.002em;
  font-weight: 500;
  margin-bottom: 30px;
  color: #65735b;
}

.banner-headline p span {
  color: #fcaf17;
  font-weight: 600;
}

.hero_image_wrap {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 90%;
  left: 10%;
  z-index: 0;
  overflow: hidden;
  background-image: url('../images/home-background.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: 100% 60%;
}

.hero_section::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(to right, rgba(250, 250, 250, 1) 20%, rgba(250, 250, 250, .95) 50%, rgba(250, 250, 250, 0.1) 80%)
}

.hero_section::after {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  display: block;
  top: 0;
  left: 0;
  z-index: 1;
  background: linear-gradient(to right, rgba(250, 250, 250, 1) 20%, rgba(250, 250, 250, .95) 50%, rgba(250, 250, 250, 0.1) 80%, rgba(250, 250, 250, 0) 100%);
}

.for-client-section {
  /* margin: 55px 0; */
  padding: 55px 0;
  position: relative;
}

.for-client-section .bg-client-section {
  border-radius: 8px;
  left: -15px;
  right: -15px;
  overflow: hidden;
  position: absolute;
  top: 0;
  bottom: 0;
  background-image: url('../images/freelancer-banner.jpg');
  background-size: cover;
  background-position: top;
  z-index: -1;
}

.for-client-section.bg-client {
  /* background-color: rgba(0,0,0,0.05); */
  background-image: url('../images/freelancer-banner-2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-attachment: fixed;
  background-position: top;
  /* z-index: -1; */
}

.headline {
  font-size: 24px;
  line-height: 32px;
  font-weight: 500;
  color: #FFF;
  margin-bottom: 100px;
  font-family: var(--font-roboto);
}

.section-headding {
  font-size: 72px;
  line-height: 72px;
  font-weight: 600;
  color: #FFF;
  margin-bottom: 30px;
  font-family: var(--font-roboto);
}

.section-subtitle {
  font-size: 18px;
  line-height: 24px;
  font-weight: 500;
  color: #FFF;
  margin-bottom: 20px;
  font-family: var(--font-roboto);
}

.headline.dark, .section-headding.dark, .section-subtitle.dark {
  color: #313131;
}

.pd-100 {
  padding-bottom: 100px;
}

.mb-60 {
  margin-bottom: 60px;
}

.hr-work {
  margin-top: 18px;
  margin-bottom: 10px;
  /* border: 1px solid #d5e0d5;
  background: #d5e0d5; */
  background: #7f828e;
  opacity: 0.25;
  /* border-top: 1px solid; */
  box-sizing: content-box;
  height: 0;
  overflow: visible;
}

.tag-title {
  font-size: 18px;
  line-height: 21px;
  font-weight: 400;
  color: #FFF;
  font-family: var(--font-roboto);
}

.tag-title.dark {
  color: #313131;
}

.btn-opportunities {
  font-size: 15px;
  line-height: 16px;
  font-weight: 400;
  color: #FFF;
  background: #072AC8;
  border: 1px solid #072AC8;
  padding: 12px 29px;
  text-decoration: none;
  display: inline-block;
  margin: 30px 20px 10px 0;
  max-height: 40px;
  text-align: center;
  vertical-align: middle;
  white-space: nowrap;
  user-select: none;
  transition: all .15s;
  cursor: pointer;
}

.btn-opportunities:hover {
  text-decoration: none;
  color: #FFF;
  background-color: rgba(7, 42, 200, 0.8);
  border-color: rgba(7, 42, 200, 0.8);
}

.btnn_primary {
  border-radius: 8px;
  font-size: 15px;
  line-height: 16px;
  /* font-family: 'Noto Sans JP', sans-serif; */
  font-weight: 500;
  text-align: left;
  width: 100%;
  background: #072AC8;
  color: #FFF;
  border-color: transparent;
  display: inline-block;
  text-decoration: none;
  padding: 15px;
}

.btnn_primary2 {
  border-radius: 5px;
  font-size: 15px;
  line-height: 16px;
  /* font-family: 'Noto Sans JP', sans-serif; */
  font-weight: 500;
  /* text-align: left; */
  width: 100%;
  background: #072AC8;
  color: #FFF;
  border-color: transparent;
  display: inline-block;
  text-decoration: none;
  padding: 15px;
  text-align: center;
  margin-top: 25px;
}

.btnn_primary .title {
  font-size: 36px;
  line-height: 36px;
  margin-bottom: 40px;
  display: block;
}

.btnn_primary2 .title {
  font-size: 18px;
  line-height: 20px;
  /* margin-bottom: 40px; */
  display: block;
}

.btnn_primary2 .title i {
  margin-left: 5px;
  opacity: 0;
  max-width: 0;
  transform: translateX(-20px);
  display: inline-block;
  transition: 0.25s;
}

.btnn_primary .name {
  font-size: 18px;
  line-height: 22px;
  text-align: left;
}

.btnn_primary .name sup {
  font-size: 8px;
  line-height: 18px;
}

.btnn_primary .name i {
  margin-left: 5px;
}

.btnn_primary:hover, .btnn_primary2:hover {
  background: #FFF;
  color: #072AC8;
  text-decoration: none;
}

.btnn_primary2:hover .title i {
  transform: translateX(0);
  opacity: 1;
  max-width: 18px;
}

.trusted-section {
  padding: 15px 0;
  border-bottom: 1px solid #dee0e4;
}

.logobar-title {
  font-size: 16px;
  line-height: 24px;
  color: #9aaa97;
  font-weight: 500;
  margin-bottom: 0px;
}

/* .trusted-section ul li {
  padding: 0 15px;
} */

.trust-ul li img {
  height: 40px;
  margin-right: 25px;
}

.trust-ul li:last-child img {
  margin-right: 0;
}

.for-freelancer-section {
  margin: 55px 0;
  position: relative;
}

.bg-freelancer-wrap {
  border-radius: 8px;
  left: -15px;
  right: -15px;
  overflow: hidden;
  display: flex;
  position: absolute;
  top: 0;
  bottom: 0;
  z-index: -1;
}

.bg-freelancer-wrap .bg-freelancer-section {
  width: 50%;
  background-image: url('../images/find-freelancer-banner-home-1.jpg');
  background-size: cover;
  background-position: top;
}

.bg-freelancer-wrap .bg-freelancer-section-blue {
  width: 50%;
  background: #d9dce8;
}

.customer-stories-section {
  margin: 55px 0;
}

.title-rebrand {
  font-size: 42px;
  line-height: 42px;
  /* font-size: 72px;
  line-height: 72px; */
  font-weight: 800;
  color: #535356;
  margin-bottom: 80px;
  font-family: var(--font-sf-pro-text);
  position: relative;
  /* text-transform: uppercase; */
}

/* .title-rebrand::after {
  content: 'What they’re saying';
  text-transform: uppercase;
  position: absolute;
  left: 50%;
  top: 0;
  font-size: 80px;
  opacity: 0.3;
  transform: translateX(-50%);
  width: 100%;
  color: #dadada;
  z-index: -1;
} */

.customer-wrapper {
  width: 100%;
  /* width: calc(71.42857% - 8.57143px); */
  margin: 0;
  margin-left: auto;
}

.customer-reviews-wrap {
  width: 100%;
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  position: relative;
  padding: 25px 25px;
  border-radius: 5px;
  box-shadow: 0 3px 8px rgba(7, 42, 200, 0.08), 0 4px 12px rgba(7, 42, 200, 0.08);
}

.image_wrpa {
  width: 40px;
  height: 40px;
  display: inline-block;
  position: relative;
  margin-right: 15px;
  border-radius: 50%;
  overflow: hidden;
  /* max-width: 50px; */
}

.image_wrpa img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.customer-content {
  width: calc(80% - 6px);
  margin: 0;
}

.customer-content img {
  border-radius: 8px;
  width: 100%;
}

.cutomer-review-title {
  font-size: 18px;
  line-height: 25px;
  /* font-size: 26px;
  line-height: 30px; */
  color: var(--color-gray-01);
  margin-top: 40px;
  margin-bottom: 15px;
  /* margin-bottom: 30px; */
  font-weight: 500;
  font-family: var(--font-roboto);
  position: relative;
  padding-top: 36px;
}

.cutomer-review-title.new-ti .quote {
  color: #072AC8;
  font-size: 128px;
  display: inline-block;
  width: 100%;
  margin-left: 0;
  position: absolute;
  top: 36px;
  opacity: 0.2;
}

/* .cutomer-review-title .quote {
  margin-left: -12px;
} */

.review-hr {
  /* background: #001e00; */
  background: #c1bebe;
  margin-top: 15px;
  margin-bottom: 20px;
  width: 100%;
}

.review-by {
  display: flex;
}

.review-user-name {
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 0px;
  font-weight: 500;
  display: block;
}

.review-user-role {
  color: var(--color-gray-02);
  /* color: #65735b; */
  font-size: 13px;
  line-height: 15px;
  font-weight: 500;
}

.skills-section {
  width: 100%;
  padding: 55px 0;
}

.skill-title {
  font-size: 24px;
  line-height: 24px;
  cursor: pointer;
  color: #d5e0d5;
  letter-spacing: 0;
  /* margin-bottom: 15px; */
  white-space: nowrap;
  font-weight: 600;
  border-radius: 2px;
  padding: 15px 0 15px 25px;
  /* padding-left: 25px; */
  border-left: 3px solid transparent;
  font-family: var(--font-sf-pro-text);
}

.skill-title sup {
  font-size: 10px;
}

.skill-title.active {
  color: #072AC8;
  border-left: 3px solid #072AC8;
  background: rgb(7 42 200 / 2%)
}

.skills-list-titles--mobile {
  scroll-behavior: smooth;
  overflow-y: hidden;
  overflow-x: scroll;
  margin-bottom: 40px;
  white-space: nowrap;
  -ms-overflow-style: none;
  scrollbar-width: none;
}

.skills-list-titles--mobile::-webkit-scrollbar {
  display: none;
}

.skills-list-titles--mobile>h2 {
  margin-right: 15px;
  font-size: 24px;
  line-height: 28px;
  margin-bottom: unset;
  white-space: unset;
  display: inline-block;
  cursor: pointer;
}

.skills-list-titles--mobile>h2:first-child {
  margin-left: 15px
}

.list-skills {
  list-style: none;
  width: 100%;
  display: inline-block;
  position: relative;
  padding-left: 0;
  /* background: rgb(7 42 200 / 2%); */
  padding: 25px;
  border-radius: 6px;
  box-shadow: 0 3px 8px rgba(7, 42, 200, 0.08), 0 4px 12px rgba(7, 42, 200, 0.08);
}

.list-skills li {
  width: calc(100% / 2);
  float: left;
}

.list-skills li a {
  font-size: 18px;
  line-height: 22px;
  font-weight: 500;
  color: #65735b;
  margin-bottom: 15px;
  display: inline-block;
  position: relative;
  text-decoration: none;
}

.list-skills li a:hover {
  color: #072AC8;
  text-decoration: none;
}

/* react-datepicker-wrapper */

.react-datepicker-wrapper {
  width: 100%;
}

.calender-education .react-datepicker__navigation::before {
  line-height: 1.5;
  top: 50%;
  transform: translateY(-50%);
}

.calender-education .react-datepicker__year-wrapper .react-datepicker__year-text {
  width: inherit;
  display: inline-block;
  margin: 0;
  padding: 5px 10px;
}

/* react-datepicker-wrapper over */

/* click block */

.fc-timegrid-event {
  /* pointer-events: none;
  cursor: default;
  text-decoration: none;
  color: black; */
}

.fc-event[href], .navLink-disable {
  pointer-events: none;
  cursor: no-drop;
}

.navLink-disable:hover {
  cursor: no-drop;
}

/* click block over */

@media screen and (max-width:992px) {
  .hero_section::after, .hero_section::before {
    background: rgba(250, 250, 250, 0.7);
  }
  .banner-headline {
    max-width: 100%;
  }
  .hero_image_wrap {
    background-image: url('../images/home-background.jpg');
    background-size: cover;
    height: 100%;
    width: 100%;
    left: 0;
  }
  .banner-headline h1 {
    font-size: 52px;
    line-height: 52px;
  }
  .banner-headline p {
    font-size: 20px;
    line-height: 26px;
  }
  .for-client-section .bg-client-section {
    left: 0;
    right: 0;
    border-radius: 0;
  }
  .btnn_primary .title {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 0px;
    margin-top: 0px;
    display: inline-block;
  }
  .btnn_primary .name {
    /* width: inherit; */
    display: inline-block;
    margin-top: 15px;
    float: unset;
  }
  .bg-freelancer-wrap {
    left: 0;
    right: 0;
    border-radius: 0;
  }
  .list-skills li a {
    font-size: 16px;
    line-height: 18px;
  }
  .skill-title {
    font-size: 28px;
  }
}

@media screen and (max-width:767px) {
  .btnn_primary {
    margin-bottom: 10px;
  }
  .btnn_primary .title {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 0px;
    margin-top: 0px;
    display: inline-block;
  }
  .btnn_primary .name {
    /* width: inherit; */
    display: inline-block;
    margin-top: 15px;
    float: right;
  }
  .bg-freelancer-wrap {
    flex-direction: column;
  }
  .bg-freelancer-wrap .bg-freelancer-section, .bg-for-talent {
    min-height: 375px;
  }
  .mb-60 {
    margin-bottom: 30px;
  }
  .pd-100 {
    padding-bottom: 40px;
  }
  .bg-freelancer-wrap .bg-freelancer-section, .bg-freelancer-wrap .bg-freelancer-section-blue {
    width: 100%;
  }
  .title-rebrand {
    font-size: 42px;
    line-height: 42px;
  }
  .customer-wrapper {
    width: 100%;
    overflow: hidden;
    margin-bottom: 50px;
  }
  .customer-content img {
    width: 95px;
    float: left;
  }
  .cutomer-review-title {
    font-size: 15px;
    line-height: 20px;
    /* margin-left: 130px; */
    margin-top: 0;
    margin-bottom: 5px;
  }
  .review-hr {
    margin-top: 10px;
    margin-bottom: 15px;
  }
  .customer-content {
    margin-left: 130px;
  }
  .customer-content.m-mobile-0 {
    margin-left: 0;
  }
  .review-user-name {
    font-size: 14px;
    line-height: 16px;
    margin-bottom: 0;
  }
  .review-user-role {
    font-size: 12px;
    line-height: 14px;
  }
  .skill-title {
    padding: 15px;
  }
  .skill-title.active {
    border-left: 0;
    border-bottom: 3px solid #072AC8;
  }
  .skills-list-titles--mobile {
    margin-bottom: 20px;
  }
}

@media screen and (max-width:576px) {
  .trust-ul li img {
    height: 27px;
  }
  .logobar-title {
    text-align: center;
    margin-bottom: 15px;
  }
  .headline {
    font-size: 20px;
    line-height: 26px;
  }
  .section-headding {
    font-size: 42px;
    line-height: 42px;
  }
  .section-subtitle {
    font-size: 16px;
    line-height: 21px;
    font-weight: 500;
  }
  .btnn_primary {
    margin-bottom: 10px;
    font-size: 15px;
    line-height: 16px;
  }
  .btnn_primary .title {
    font-size: 24px;
    line-height: 26px;
    margin-bottom: 0px;
    margin-top: 0px;
    display: inline-block;
  }
  .btnn_primary .name {
    display: inline-block;
    margin-top: 15px;
    float: right;
  }
  .customer-content {
    width: calc(100% - 130px)
  }
  .trust-ul li img {
    margin-right: 20px;
  }
}

/* home page css over*/

/* pagination */

.pagination_wrap_div {
  margin-top: 0;
  border-top: 1px solid #ededed;
  width: 100%;
  padding: 20px 0;
}

.pagination_wrap_div .pagination_wrap {
  padding: 0;
  border-top: 0;
  margin: 0;
  list-style: none;
}

.pagination_wrap_div .pagination_wrap li {
  display: inline-block;
  margin: 0 2px;
}

.pagination_wrap_div .pagination_wrap li.previous {
  margin: 0 15px 0 0px;
}

.pagination_wrap_div .pagination_wrap li.next {
  margin: 0 0px 0 15px;
}

.pagination_wrap_div .pagination_wrap li a {
  padding: 2px 6px;
  margin: 1px;
  width: 32px;
  display: inline-block;
  cursor: pointer;
  background-color: #fff;
  border: 1px solid #f5f5f5;
  border-radius: 4px;
  min-width: 1rem;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  color: #0F1626;
}

.pagination_wrap_div .pagination_wrap li a:hover {
  background: #f5f5f5;
}

.pagination_wrap_div .pagination_wrap li a:focus {
  outline: none;
}

.pagination_wrap_div .pagination_wrap li.active a {
  background: #0F1626;
  border-color: #0F1626;
  color: #FFF;
}

.pagination_wrap_div .pagination_wrap li.active a:hover {
  background: #0F1626;
  border-color: #0F1626;
  color: #FFF;
}

/* pagination over */

/* payment css */

.payment_wrap {
  width: 100%;
  padding: 50px 0;
}

.page-title {
  font-weight: 700;
  font-family: 'Noto Sans JP', sans-serif;
  margin-bottom: 35px;
}

/* payment css over */

@media screen and (max-width:1200px) {
  .mt_150 {
    margin-top: -100px;
  }
}

@media screen and (max-width:991px) {
  .mt_150 {
    margin-top: -50px;
  }
  .navbar-expand-sm .navbar-toggler {
    display: unset !important;
  }
  .navbar_collapse {
    position: fixed;
    top: 56px;
    left: -500px;
    bottom: 0;
    right: 0;
    background: #f8f9fa;
    width: 100%;
    max-width: 300px;
    z-index: 999;
    overflow: hidden;
    overflow-y: auto;
    padding-top: 25px;
  }
  .navbar_collapse.show {
    left: 0;
    align-items: flex-start;
  }
  .navbar_collapse .navbar-nav .nav-link {
    padding: 10px 15px;
    /* display: block; */
  }
  .navbar_collapse .navbar-nav {
    flex-direction: column !important;
    width: 100%;
  }
  .navbar-toggler:focus {
    outline: 0;
    box-shadow: unset;
  }
  .navbar-toggler.close_icon {
    font-size: 27px;
  }
  .overlay_sidebar {
    background: rgb(0 0 0 / 75%);
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: inline-block;
    z-index: 99;
  }
  .mobile-dropdown .dropdown-toggle[aria-expanded="true"]::after {
    transform: rotate(224deg);
  }
  .mobile-dropdown .dropdown-toggle[aria-expanded="true"] i {
    transform: rotate(180deg);
  }
  .mobile-dropdown.show .dropdown-menu {
    position: relative;
    padding: 0px 15px;
    box-shadow: unset;
    border-radius: 0;
    border: 0;
    animation-duration: 0.05s;
    animation-timing-function: linear;
  }
  .mobile-dropdown.show .dropdown-menu .nav-item {
    display: block;
    padding: 10px 15px;
  }
}

@media screen and (max-width:767px) {
  .mt_150 {
    margin-top: 0px;
  }
  .mt_65 {
    margin-top: -15px;
  }
  .rc-steps {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 10px;
  }
}

@media screen and (min-width:767px) {
  .navbar-expand-sm .navbar-toggler {
    display: none;
  }
}

@media screen and (max-width:576px) {
  .mobile-hide {
    display: none;
  }
  .rc-steps {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding-bottom: 10px;
  }
  .min-200 {
    min-width: 120px;
  }
}