@import url('./app.css');
#root, body, html {
    height: inherit !important;
}
body{
    overflow-y: auto !important;
}
.h1,.h2,.h3,
.h4,.h5,.h6,
.font-manul{
    font-family: "Inter", sans-serif;
}
.form-control,
.form-label{
    font-family: "Inter", sans-serif;
}

.avatar-text-wrap{
    height: 37px;
    width: 37px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    background: #dee2e6;
    font-family: "Inter", sans-serif;
    font-style: normal;
    font-weight: 700;
    color: #222e3c;
}

.drop_menu{
    inset: 37px auto auto auto !important;
    margin: unset !important;
    transform: unset !important;
    right: 0px !important;
}

.dropdown-item svg{
    stroke-width: 2;
    height: 18px;
    width: 18px;
    margin-right: 0.25rem;
    vertical-align: middle;
}
.sidebar-item .sidebar-link.active:hover, .sidebar-item>.sidebar-link.active {
    background: linear-gradient(90deg,rgba(59,125,221,.1),rgba(59,125,221,.0875) 50%,transparent);
    border-left-color: #3b7ddd;
    color: #e9ecef;
}
.sidebar-item .sidebar-link.active:hover, .sidebar-item>.sidebar-link.active svg{
    color: #e9ecef;
}

.react-bs-table-sizePerPage-dropdown button{
    background: transparent;
    border-color: #dee2e6;
    color: #495057;
    font-family: "Inter", sans-serif;
}
.react-bs-table-sizePerPage-dropdown button:hover,
.react-bs-table-sizePerPage-dropdown button:focus{
    background: rgba(0,0,0,0.0375) !important;
    border-color: #dee2e6 !important;
    outline: 0 !important;
    box-shadow: unset !important;
    color: #495057 !important;
}
.react-bs-table-sizePerPage-dropdown .dropdown-menu .dropdown-item a[role="menuitem"]{
    width: 100%;
    display: block;
    text-decoration: none;
    font-family: "Inter", sans-serif;
}
.table-wrap-header{
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}
.table-search{
    width: 100%;
    max-width: 250px;
}
.table thead tr th,
.table tbody tr td{
    font-family: "Inter", sans-serif;
}
.search-form{
    width: 100%;
    position: relative;
}
.search-form .form-control{
    padding-left: 35px;
    font-family: "Inter", sans-serif;
}
.search-form button{
    background: transparent;
    border: 0;
    position: absolute;
    top: 4.5px;
    left: 3px;
    z-index: 1;

}
.table-wrap-footer{
    padding: .75rem;
    display: flex;
    align-items: center;
    justify-content: space-between;
}
.table-wrap-footer .react-bootstrap-table-pagination-total{
    font-family: "Inter", sans-serif;
    font-size: 13px;
}
.table-wrap-footer .pagination{
    margin-bottom: 0;
}
.table-wrap-footer .pagination .page-item .page-link{
    color: #222e3c;
    border: 0;
    width:25px;
    height: 25px;
    text-align: center;
    padding:5px;
    margin: 0 5px;
    background: transparent;
    text-decoration: none;
    font-size: 12px;
    align-items: center;
    display: flex;
    justify-content: center;
    border-radius: 3px;
    font-family: "Inter", sans-serif;
}
.table-wrap-footer .pagination .page-item:last-child .page-link,
.table-wrap-footer .pagination .page-item:first-child .page-link{
    margin: 0;
}
.table-wrap-footer .pagination .page-item .page-link:hover,
.table-wrap-footer .pagination .page-item .page-link:focus{
    outline: 0;
    box-shadow: unset;
}
.table-wrap-footer .pagination .page-item .page-link:hover{
    background: #dee2e6;
}
.table-wrap-footer .pagination .page-item.active .page-link{
    background: #222e3c;
    
    color: #FFF;
}
.empty-table{
    width: 100%;
    /* text-align: center; */
}
.empty-table p{
    color: #222e3c;
    font-family: "Inter", sans-serif;
    font-size: 14px;
    font-weight: 500;
    margin: 0;
    /* text-align: center; */
}

/* submenu */
.submenu{
    padding-left: 60px;
    margin-bottom: 0;
    background: #222e3c;
    padding: 0px;
    list-style: none;
}
/* submenu over */
.custom-timezone{
    display: inline-block;
    width: 100%;
}
.custom-timezone .css-26l3qy-menu{
    z-index: 99;
}

.avaibility-table{
    max-height: 0;
    /* height: 0; */
    transition: max-height 0.2s linear;
}

.avaibility-table.active{
    max-height: inherit;
}

.select-form{
    /* width: 100%; */
    min-width: 100px;
    max-width: 100%;
    padding: 5px 15px 5px 10px;
    background: #FFF;
    border-radius: 0.3rem;
    border:1px solid #dadada;
}
.select-form:focus{
    outline: 0;
    box-shadow: none;
}

@media screen and (max-width:576px) {
    .drop_menu{
        inset: initial !important;
        right: 10px !important;
        margin: unset !important;
        transform: unset !important;
    }
}