.jobs_wrap {
    width: 100%;
    display: flex;
    padding: 50px 0;
    justify-content: flex-end;
    min-height: calc(100vh - 493px);
}

.jobs_div {
    display: inline-block;
    width: 100%;
    position: relative;
    margin-top: 0;
    /* padding: 30px; */
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
}

.tab_UL_nav{
    padding-left: 30px !important;
    padding: 30px 30px 0;
}

.page-title{
    font-size: 30px;
    font-weight: 600;
    font-family: 'Noto Sans JP', sans-serif;
    color: #25313c;
}

.jobs_div .nav_ul {
    display: flex;
    margin: 0 0 30px;
    border-bottom: 1px solid #e8e8e8;
    padding: 0;
    list-style: none;
}
.jobs_div .nav_ul.cus-nav-ul{
    padding: 30px 30px 0;
  }

.jobs_div .nav_ul li {
    padding: 0 10px 8px;
    text-align: center;
    position: relative;
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 16px;
    font-weight: 400;
    color: var(--grayWeb);
    cursor: pointer;
    text-align: center;
    transition: 0.3s all;
}

.jobs_div .nav_ul li.active {
    font-weight: 500;
    color: var(--cyberYellow);
}

.jobs_div .nav_ul li.active::after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: var(--cyberYellow);
    width: 100%;
    height: 2px;
}

.jobs_div .search_jobs {
    width: 100%;
    display: inline-block;
    position: relative;
    /* margin-bottom: 30px; */
    /* padding-bottom: 30px; */
    padding: 30px;
    border-bottom: 1px solid #e8e8e8
}

.jobs_div .search_jobs form{
    position: relative;
}

.jobs_div .search_jobs .search_input {
    padding: 8px 40px 8px 20px;
    height: 40px;
    border-radius: 3px;
}

.jobs_div .search_jobs .btn_search {
    background: var(--cyberYellow);
    border-radius: 0 3px 3px 0;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    width: 40px;
    color: #FFF;
    font-size: 23px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.jobs_div .jobsList {
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 0;
    padding: 0;
    list-style: none;
}

.jobs_div .jobsList li {
    font-family: 'Noto Sans JP', sans-serif;
}
.jobs_div .jobsList li a{
    text-decoration: none;
}
.jobs_div .jobsList li a:hover{
    color: inherit;
}
.jobs_div .jobsList li .projectList_div {
    display: flex;
    width: 100%;
    /* margin-bottom: 30px; */
    position: relative;
    /* border-radius: 8px; */
    transition: all .3s ease-out;
    padding: 30px 30px 20px;
    align-items: flex-start;
    justify-content: space-between;
    border-bottom: 1px solid #f5f5f5;
    border-left: 0;
    border-right: 0;
    transition: box-shadow 0.3s ease;
    cursor: pointer;
}

.jobs_div .jobsList li:last-child .projectList_div{
    border-bottom: 0;
}
.jobs_div .jobsList li.list-nofound{
    padding: 30px;
}

.jobs_div .jobsList li .projectList_div:hover {
    /* box-shadow: 0 6px 20px rgb(12 15 38 / 10%); */
    background: #f9f9f9;
}
.jobs_div .jobsList li .projectList_div.div-flex{
    align-items: center;
}
.jobs_div .jobsList li .projectList_div .left_wrap {
    width: 100%;
}

.jobs_div.all-contract .jobsList li .projectList_div.div-flex .left_wrap .image_wrap{
    width: 50px;
    height: 50px;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}
.jobs_div.all-contract .jobsList li .projectList_div.div-flex .left_wrap .image_wrap img{
    width: 100%;
    object-fit: cover;
}

.jobs_div .jobsList li .projectList_div .left_wrap .job_title_wrap {
    display: flex;
    justify-content: space-between;
    margin-bottom: 15px;
}
.jobs_div .jobsList li .projectList_div .left_wrap .job_title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 10px;
    line-height: 20px;
    letter-spacing: .4px;
    color: var(--midnight);
    /* color: var(--richBlack); */
    cursor: pointer;
    word-break: break-all;
}
.btn-save-btn{
    background: #FFF;
    border-radius: 50%;
    width: 35px;
    height: 35px;
    border:1px solid #9aaa97;
    font-size: 16px;
    color: #9aaa97;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
}
.btn-save-btn:hover{
    background: #f9f9f9;
}

.jobs_div .jobsList li .projectList_div .left_wrap .details_text {
    width: 100%;
    display: inline-block;
    position: relative;
    font-family: 'Noto Sans JP', sans-serif;
}

.jobs_div .jobsList li .projectList_div .left_wrap .details_text p {
    color: var(--grayWeb);
    display: -webkit-box;
    display: -moz-box;
    display: -o-box;
    display: -ms-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -o-line-clamp: 2;
    -o-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
}

.jobs_div .jobsList li .projectList_div .left_wrap .details_text ul {
    padding-left: 0;
}

.jobs_div .jobsList li .projectList_div .left_wrap .listing {
    margin-top: 15px;
    padding: 0;
    margin-bottom: 0;
}

.jobs_div .jobsList li .projectList_div .left_wrap .listing li {
    display: inline-block;
    font-size: 14px;
    color: #646464;
    font-weight: 500;
    position: relative;
    margin: 0 30px 25px 0;
    padding-left: 12px;
    letter-spacing: .28px;
}

.jobs_div .jobsList li .projectList_div .left_wrap .listing li::before {
    content: "";
    position: absolute;
    left: 0;
    top: 7px;
    width: 6px;
    height: 6px;
    background: var(--blue);
    /* background: #1a62f5; */
    border-radius: 50%;
}

.jobs_div .jobsList li .projectList_div .left_wrap .status_wrap {
    width: 100%;
    display: inline-block;
    position: relative;
}

.jobs_div .jobsList li .projectList_div .left_wrap .status_wrap .btn_status {
    border: 0;
    background: #efefef;
    padding: 5px 15px;
    border-radius: 25px;
    font-size: 13px;
    color: var(--bs-gray);
    color: #FFF;
}

.jobs_div .jobsList li .projectList_div .left_wrap .status_wrap .btn_status.yellow {
    background: var(--cyberYellow);
}

.jobs_div .jobsList li .projectList_div .left_wrap .status_wrap .btn_status.blue {
    background: var(--blue);
}

.jobs_div .jobsList li .projectList_div .left_wrap .status_wrap .btn_status.green {
    background: var(--colorGreen);
}

.jobs_div .jobsList li .projectList_div .left_wrap .status_wrap .btn_status.red {
    background: var(--errorColor);
}

.jobs_div .jobsList li .projectList_div .left_wrap .status_wrap .btn_status.black {
    background: var(--richBlack);
}

.jobs_div .jobsList li .projectList_div .left_wrap .status_wrap .btn_status.purple {
    background: var(--bs-purple);
}

.filter_wrap {
    width: 100%;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 30px; */
    background: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 20px;
    transition: 0.4s linear;
}

.main_filter_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 15px; */
    font-family: 'Noto Sans JP', sans-serif;
}

.collapse_filter {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 15px;
    transition: display 0.3s linear;
}

.filter_icon {
    cursor: pointer;
}

.main_filter_div h3 {
    font-size: 22px;
    font-weight: 700;
}

.filter_radio button {
    width: calc(50% - 10px);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
    color: rgb(0, 0, 0);
    justify-content: center;
    line-height: 20px;
    margin-bottom: 0px;
    padding: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
    border-image: initial;
    border-radius: 3px;
    background: transparent;
}

.filter_radio button.active {
    color: rgb(255, 255, 255);
    background: #0F1626;
    border-color: #0F1626;
}

.main_area h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
}

.division {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(206, 206, 206);
}

.posting {
    display: flex;
    justify-content: space-between;
}

.radio_group {
    flex-wrap: wrap;
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
    padding-left: 0;
    position: relative;
    display: flex;
    flex-direction: column;
}

.radio_group li {
    width: 100%;
    /* width: calc(50% - 5px); */
    margin-bottom: 0px;
    /* margin-bottom: 10px; */
    /* display: inline-block; */
    list-style: none;
}

.radio_group li label {
    width: 100%;
    color: #a7a7a7;
    font-size: 14px;
    /* border: 1px solid #a7a7a7; */
    border:0;
    font-weight: 500;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    text-align: left;
    /* text-align: center; */
    /* padding: 4px; */
    /* justify-content: flex-start; */
    line-height: 16px;
    border-radius: 3px;
    margin-bottom: 0;
    cursor: pointer;
}

.radio_group li label:hover{
    text-decoration: underline;
    color: #1a62f5;
}

.radio_group li label.active {
    /* background: #0F1626;
    color: #fff;
    border-color: #0F1626; */
    color: #1a62f5;
    font-weight: 500;
}

/* .pagination_wrap {
    padding-top: 1rem;
    text-align: center;
}

.pagination_wrap>ul {
    padding: 0;
    margin: 0;
}

.pagination_wrap li {
    display: inline-block;
    margin: 0 2px;
}

.pagination_wrap li.previous {
    margin: 0 15px 0 0px;
}

.pagination_wrap li.next {
    margin: 0 0px 0 15px;
}

.pagination_wrap li>a {
    padding: 2px 6px;
    margin: 1px;
    width: 32px;
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    min-width: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #0F1626;
}

.pagination_wrap li>a:hover {
    background: #f5f5f5;
}

.pagination_wrap li>a:focus {
    outline: none;
}

.pagination_wrap li.active>a {
    background: #0F1626;
    border-color: #0F1626;
    color: #FFF;
}

.pagination_wrap li.active>a:hover {
    background: #0F1626;
    border-color: #0F1626;
    color: #FFF;
} */

/* details page design */

.bg_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(0 0 0 / 55%);
    z-index: 9995;
    display: none;
}
.bg_overlay.show{
    display: block;
}
.details_wrap{
    width: 100%;
    max-width: calc(100% - 30vw);
    position: fixed;
    right: -100%;
    top: 0;
    bottom: 0;
    z-index: 9996;
    overflow: hidden;
    overflow-y: auto;
    background: #EFEFEF;
    transition: right 0.3s linear;
    /* display: none; */
}
.details_wrap.show{
    right: 0;
    /* right: -100%; */
    /* display: block; */
}
.header_wrap{
    width: 100%;
    display: inline-block;
    position: relative;
    background: #FFF;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    padding: 15px 25px;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
}
.header_wrap .back_wrap{
    background: transparent;
    border: 0;
    padding: 0;
    font-size: 28px;
    color: #0F1626;
}
.details_wrap_body{
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 25px 30px;
}
.wrap_detail{
    width: 100%;
    max-width: calc(100% - 10vw);
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
}
.button_wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 0;
    border-bottom: 1px solid #EFEFEF;
}
.wrap_detail .title_wrap_details{
    padding: 25px 15px;
    border-bottom: 1px solid #EFEFEF;
}
.wrap_detail .title_wrap_details h3{
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
}
.wrap_detail .description_wraps{
    padding: 25px 15px;
    border-bottom: 1px solid #EFEFEF;
}
.wrap_detail .description_wraps p{
    font-size: 15px;
    line-height: 22px;
}
.price_wrap,
.type_project{
    padding: 25px 15px;
    border-bottom: 1px solid #EFEFEF;
}
.price_wrap p{
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: #0F1626;
}
.price_wrap p span{
    display: block;
    opacity: 0.5;
    font-size: 14px;
}
.type_project p{
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: #0F1626;
}
.type_project p span{
    font-weight: 400;
}
.skills_wrap_detail{
    padding: 25px 15px;
}
.skills_wrap_detail h5{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}
.skills_wrap_detail p{
    font-size: 16px;
    font-weight: 500;
    color: #0F1626;
    margin-bottom: 10px;
}
.skills_wrap_detail .budges_skills{
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}
.skills_wrap_detail .budges_skills li{
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 500;
    background: #e2e2e2;
    display: inline-block;
    margin: 0 10px 10px 0px;
    border-radius: 30px;
}
.border_end{
    border-right: 1px solid #EFEFEF;
}
.bid_label{
    margin-bottom: 0;
    font-size: 14px;
    font-family: 'Roboto', sans-serif;
    margin-left: 15px;
    font-weight: 400;
}
.bid_label a span{
    color: #1a62f5;
    text-decoration: underline;
    font-weight: 600;
}
.div-views p{
    color: #707070;
}
.div-views p:first-child{
    font-weight: 600;
    color: #0F1626;
}
/* details page design over*/
@media screen and (max-width:1440px) {
    .details_wrap{
        max-width: calc(100% - 15vw);
    }
}
@media screen and (max-width:1199px) {
    .details_wrap{
        max-width: calc(100% - 5vw);
    }
}
@media screen and (max-width:991px) {
    .details_wrap{
        max-width: calc(100% - 5vw);
    }
    .wrap_detail{
        max-width: 100%;
    }
    .button_wrap{
        flex-direction: row-reverse;
        align-items: center;
        justify-content: space-between;
        border-top: 1px solid #EFEFEF;
        padding: 35px 15px;
    }
    .button_wrap button:first-child{
        margin-bottom: 0 !important;
    }
    .border_end{
        border-right: 0;
    }
    .jobs_div .jobsList li .projectList_div.div-flex{
        flex-direction: column;
    }
    .jobs_div .jobsList li .projectList_div.div-flex .left_wrap{
        margin-bottom: 15px;
    }
}
@media screen and (max-width:767px) {
    .collapse_filter {
        display: none;
    }
    .collapse_filter.active {
        display: inline-block;
    }
    .jobs_div{
        margin-top: 25px;
    }
    .jobs_div.allJobs{
        padding: 0;
        box-shadow: unset;
        background: transparent;
    }
    .jobs_div.allJobs .jobsList li .projectList_div{
        background: #FFF;
        padding: 15px;
    }
    .jobs_div.allJobs .jobsList li .projectList_div .left_wrap .job_title{
        font-size: 18px;
    }
    .jobs_div.allJobs .jobsList li .projectList_div .left_wrap .details_text{
        font-size: 12px;
    }
    .details_wrap_body{
        padding: 25px 15px;
    }
    .details_wrap{
        max-width: 100%;
    }
    .wrap_detail{
        max-width: 100%;
    }
    
    
}
@media screen and (max-width:576px) {
    .button_wrap button{
        min-width: 5vw !important;
    }
    .jobs_div .search_jobs{
        padding: 20px 15px;
    }
    .jobs_div .jobsList li .projectList_div{
        padding: 30px 15px 20px;
    }
    .jobs_div .jobsList li .projectList_div .left_wrap .job_title{
        font-size: 16px;
        padding-right: 20px;
    }
    
}
@media screen and (max-width:320px) {
    .button_wrap{
        flex-direction: column;
    }
}