.img {
    max-width: 100%;
}

.inbox_people {
    background: #FBFBFB;
    float: left;
    overflow: hidden;
    width: 20%;
    display: block;
    height: 100vh;
    max-height: calc(100vh - 58px);
    border-right: 1px solid #c4c4c4;
    position: fixed;
    left: 0;
    top: 56px;
    z-index: 5;
}

.inbox_msg {
    border: 1px solid #c4c4c4;
    clear: both;
    overflow: hidden;
}

.top_spac {
    margin: 20px 0 0;
}

.recent_heading {
    float: left;
    width: 40%;
}

.srch_bar {
    display: inline-block;
    /* text-align: right; */
    width: 100%;
}

.headind_srch {
    padding: 5px 10px 5px 10px;
    overflow: hidden;
    border-bottom: 1px solid #c4c4c4;
    display: block;
}

.recent_heading h4 {
    color: #0F1626;
    font-size: 21px;
    margin: auto;
}

.stylish_input_group {
    width: 100%;
    position: relative;
    display: inline-block;
}

.srch_bar input {
    border: 1px solid #cdcdcd;
    border-width: 0 0 0px 0;
    width: 100%;
    padding: 2px 30px 4px 6px;
    background: none;
}

.srch_bar input:focus {
    outline: 0;
}

.srch_bar .input_group_addon button {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    padding: 0;
    color: #707070;
    font-size: 18px;
}

.srch_bar .input_group_addon {
    margin: 0 0 0 -27px;
}

.chat_ib h5 {
    font-size: 15px;
    color: #464646;
    margin: 0 0 0px 0;
    word-break: break-all;
}

.chat_ib h5 span {
    font-size: 13px;
    float: right;
}

.chat_ib p {
    font-size: 14px;
    color: #989898;
    margin: auto;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
}

.chat_img_wrap {
    float: left;
    width: 45px;
    position: relative;
}

.chat_img {
    width: 45px;
    height: 45px;
    overflow: hidden;
    border-radius: 50%;
    position: relative;
}

.chat_img .shape {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    color: #0F1626;
    font-weight: 500;
    background: #c4c4c4;
}

.loader-message {
    font-size: 15px;
    text-align: center;
    color: rgba(0, 0, 0, 0.5);
}

.chat_img .img {
    width: 100%;
    object-fit: cover;
    height: 100%;
}

.chat_img_wrap::after {
    content: '';
    position: absolute;
    bottom: 0;
    right: 5px;
    width: 10px;
    height: 10px;
    display: inline-block;
    border-radius: 50%;
    background: #90969c;
    border: 1px solid #FFF;
}

.chat_img_wrap.active::after {
    background: #22bb0f;
}

.chat_ib {
    float: left;
    padding: 0 0 0 15px;
    width: calc(100% - 45px);
    min-height: 45px;
    display: flex;
    align-items: center;
}

.chat_people {
    overflow: hidden;
    clear: both;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

.chat_people .user-profil {
    width: calc(100% - 25px);
}

.notification-icon {
    /* width: 25px;
    height: 25px; */
    /* padding: 0.25rem; */
    /*border-radius: 50%; */
    background: #05728f;
    display: inline-block;
    position: relative;
    color: #FFF;
    font-size: 13px;
}

.chat_list {
    border-bottom: 1px solid #c4c4c4;
    margin: 0;
    padding: 18px 16px 10px;
    cursor: pointer;
}

.inbox_chat {
    max-height: calc(100vh - 96px);
    overflow: hidden;
    overflow-y: auto;
    /* background: #FFF; */
    /* border-right: 1px solid #c4c4c4; */
}

.active_chat {
    background: #ebebeb;
}

.incoming_msg_img {
    display: inline-block;
    width: 25px;
}

.incoming_msg_img .name_or_img {
    
    width: 25px;
    height: 25px;
    border-radius: 50%;
    overflow: hidden;
    /* display: flex;
    align-items: center;
    justify-content: center; */
}

.incoming_msg_img .name_or_img .shape {
    font-size: 13px;
    font-weight: 600;
    background: #e0e0e0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content:center;
    color: #0F1626;
}

.incoming_msg_img .name_or_img .img {
    max-width: 100%;
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.received_msg {
    display: inline-block;
    padding: 0 0 0 10px;
    vertical-align: top;
    width: 92%;
}

.received_withd_msg p {
    background: #ebebeb none repeat scroll 0 0;
    border-radius: 3px;
    color: #646464;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    display: inline-block;
    /* width: 100%; */
}

.time_date {
    color: #747474;
    display: block;
    font-size: 12px;
    margin: 8px 0 0;
}

.received_withd_msg {
    width: 57%;
    margin-bottom: 10px;
    overflow: hidden;
}

.incoming_msg:last-child .received_msg .received_withd_msg {
    margin-bottom: 0;
}

.mesgs {
    /* float: right; */
    padding: 0px;
    width: calc(100% - 20%);
    background: #fbfbfb;
    position: fixed;
    right: 0;
    top: 56px;
}

.sent_msg p {
    background: #05728f none repeat scroll 0 0;
    border-radius: 3px;
    font-size: 14px;
    margin: 0;
    color: #fff;
    padding: 5px 10px 5px 12px;
    display: inline-block;
    /* width: 100%; */
}

.outgoing_msg {
    overflow: hidden;
    margin: 26px 0 26px;
    /* width: 46%; */
}

.div_limit {
    float: right;
    max-width: 46%;
}

.sent_msg {
    /* display: inline-block; */
    display: flex;
    justify-content: flex-end;
    flex-direction: column;
    position: relative;
}

.sent_msg .btn-delete-message {
    position: absolute;
    left: -35px;
    top: 0;
    border: 0;
    background: #e6e6e6;
    width: 30px;
    height: 30px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 0;
    border-radius: 50%;
    transform: scale(0);
    transition: width 0.5 linear;
}

.outgoing_msg:hover .sent_msg .btn-delete-message {
    transform: scale(1);
}

.sent_msg .btn-delete-message:hover {
    width: auto;
    left: -150px;
    border-radius: 20px;
    padding: 5px 10px;
    color: #05728f;
}

.sent_msg .btn-delete-message span {
    display: none;
    margin-left: 5px;
    width: 0;
    transition: 0.3s all;
}

.sent_msg .btn-delete-message:hover span {
    display: inline-block;
    width: auto;
}

.input_msg_write input {
    background: rgba(0, 0, 0, 0) none repeat scroll 0 0;
    border: medium none;
    color: #4c4c4c;
    font-size: 15px;
    min-height: 48px;
    width: 100%;
    padding: 0 115px 0 0;
}

.input_msg_write input:focus {
    outline: 0;
}

.type_msg {
    border-top: 1px solid #c4c4c4;
    border-left: 1px solid transparent;
    /* position: relative; */
    width: 80%;
    position: fixed;
    bottom: 0;
    right: 0;
    background: #FFF;
    padding: 0 15px;
}

.type_msg .input_msg_write {
    position: relative;
}

.type_msg .input_msg_write .files_input_label {
    margin-right: 15px;
    cursor: pointer;
}

.type_msg .input_msg_write .files_input_label input[type="file"] {
    display: none;
}

.type_msg .input_msg_write .files_input_label .file_input_icon {
    font-size: 20px;
}

.type_msg .input_msg_write .files_input_label .file_input_icon svg {
    transform: rotate(35deg);
}

.type_msg .input_msg_write .files_and_buttons {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translate(0, -50%);
    cursor: pointer;
}

.msg_send_btn {
    background: #05728f none repeat scroll 0 0;
    border: medium none;
    /* border-radius: 50%; */
    color: #fff;
    cursor: pointer;
    font-size: 15px;
    height: 33px;
    /* position: absolute;
    right: 0;
    top: 11px; */
    /* width: 33px; */
}

.file_array_ul {
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    padding: 10px 0px 15px 0px;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 0;
    list-style: none;
}

.file_array_ul li {
    float: left;
    display: flex;
    width: 100%;
    max-width: 150px;
    overflow: hidden;
    padding: 10px;
    background: #f1f1f1;
    border-radius: 5px;
    margin-right: 5px;
}

.file_array_ul li:last-child {
    margin-right: 0;
}

.file_array_ul li .file_box {
    /* width: 100%; */
    width: 50px;
    height: 50px;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}

.file_array_ul li .file_box .icon {
    font-size: 55px;
}

.file_array_ul li .file_box .image {
    width: 100%;
    object-fit: cover;
}

.file_array_ul li .overlay_img {
    width: calc(100% - 50px);
    /* background: rgba(0, 0, 0, 0.5); */
    z-index: 2;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    padding-left: 5px;
}

.file_array_ul li .overlay_img .file-nam {
    font-size: 12px;
    color: #0F1626;
    margin-bottom: 0;
    white-space: nowrap;
    overflow: hidden;
    max-width: 100%;
    text-overflow: ellipsis;
}

.file_array_ul li .overlay_img .delete-file-button {
    background: transparent;
    padding: 0;
    margin-top: 2px;
    color: #fc4117;
    font-size: 13px;
    cursor: pointer;
    border: 0;
}

.messaging {
    /* padding: 0 0 50px 0; */
}

.msg_history {
    /* height: 516px; */
    height: calc(100vh - 170px);
    overflow: hidden;
    overflow-y: auto;
    padding: 30px 15px 30px 25px;
}

.no-chat-wrap {
    width: 100%;
    height: calc(100vh - 55px);
    display: flex;
    align-items: center;
    justify-content: center;
}

.no-chat-wrap.inside {
    height: calc(100vh - 250px);
}

.no-chat-wrap .text-chatmessage {
    width: 100%;
    max-width: 250px;
    margin: 0 auto;
    display: block;
    position: relative;
    text-align: center;
}

.no-chat-wrap .text-chatmessage .icon {
    display: block;
    font-size: 105px;
    opacity: 0.5;
}

.no-chat-wrap .text-chatmessage p {
    font-family: 'Noto Sans JP', sans-serif;
    color: #0F1626;
    font-weight: 500;
    font-size: 18px;
    opacity: 0.5;
}

.message-seen {
    margin-left: 15px;
    font-size: 14px;
    color: #747474;
}

.message-seen.seen {
    color: #05728f;
}

.message-header {
    width: 100%;
    height: 66px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
    background: #FFF;
    padding: 10px 15px;
    border-bottom: 1px solid #c4c4c4;
}

.message-header .title-header-wrap {
    display: inline-block;
    position: relative;
    width: 100%;
    padding-right: 15px;
}

.message-header .title-header-wrap h2 {
    font-size: 20px;
    word-break: break-all;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    max-width: 100%;
    margin-bottom: 0;
    line-height: 35px;
    font-weight: 500;
    color: #0F1626;
}

.message-header .group-btns {
    display: flex;
    position: relative;
}

.message-header .group-btns button {
    border: 0;
    background: #ebebeb;
    width: 45px;
    height: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    font-size: 16px;
    margin-right: 8px;
}
.message-header .group-btns button:last-child{
    margin-right: 0;
}
.message-header .group-btns .btn-save,
.message-header .group-btns .btn-cancel{
    padding: 5px 15px;
    border: 0;
    border-radius: 35px;
    font-size: 16px;
    width: unset;
    min-width: 75px;
    height: 35px;
}
.message-header .group-btns .btn-save{
    background: #fcaf17;
    color: #FFF;
}
.message-header .group-btns .btn-cancel{
    background: #ebebeb;
    color: #333;
}

.message-header .group-btns button:hover {
    background: #d8d7d7;
}

.overlay_loader {
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background: rgba(0, 0, 0, 0.3);
    z-index: 1;
    display: flex;
    align-items: center;
    justify-content: center;
}

/* chat typing animation */

.chat-bubble {
    background-color: transparent;
    /* background-color: #E6F8F1; */
    padding: 16px 28px;
    -webkit-border-radius: 20px;
    -webkit-border-bottom-left-radius: 2px;
    -moz-border-radius: 20px;
    -moz-border-radius-bottomleft: 2px;
    border-radius: 20px;
    border-bottom-left-radius: 2px;
    display: inline-block;
}

.typing {
    align-items: center;
    display: flex;
    height: 17px;
}

.typing .dot {
    animation: mercuryTypingAnimation 1.8s infinite ease-in-out;
    background-color: #f3f3f3;
    border-radius: 50%;
    height: 7px;
    margin-right: 4px;
    vertical-align: middle;
    width: 7px;
    display: inline-block;
}

.typing .dot:nth-child(1) {
    animation-delay: 200ms;
}

.typing .dot:nth-child(2) {
    animation-delay: 300ms;
}

.typing .dot:nth-child(3) {
    animation-delay: 400ms;
}

.typing .dot:last-child {
    margin-right: 0;
}

@keyframes mercuryTypingAnimation {
    0% {
        transform: translateY(0px);
        background-color: #e4e4e4;
    }
    28% {
        transform: translateY(-7px);
        background-color: #e4e1e1;
    }
    44% {
        transform: translateY(0px);
        background-color: #c7c7c7;
    }
}

/* chat typing animation over */

.files-block {
    background: #ebebeb;
    border-radius: 3px;
    color: #646464;
    font-size: 14px;
    margin: 0;
    padding: 5px 10px 5px 12px;
    display: inline-block;
    /* display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-between; */
    margin-bottom: 10px;
    position: relative;
}

.files-block:last-child {
    margin-bottom: 0;
}

.files-block.sender {
    background: #05728f;
}

.files-block .fileandName {
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.files-block .fileandName .image_wra {
    display: inline-block;
    width: 45px;
    margin-right: 5px;
}

.files-block .fileandName .image_wra .icon {
    font-size: 45px;
    display: inline-block;
    line-height: 39px;
}

.files-block.sender .fileandName .image_wra .icon {
    color: rgba(255, 255, 255, 0.5);
}

.files-block .fileandName .text-wrap {
    width: calc(100% - 50px);
    display: inline-block;
}

.files-block .text-wrap p {
    background: transparent;
    padding: 0;
    margin-bottom: 0;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    overflow: hidden;
}

.files-block .files-btns {
    width: 100%;
    border-top: 1px solid #c4c4c4;
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 10px 10px 5px;
}

.files-block .files-btns a, .files-block .files-btns button {
    font-size: 16px;
    text-decoration: none;
    padding: 0;
    border: 0;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
}

.files-block .files-btns a svg, .files-block .files-btns button svg {
    margin-right: 5px;
}

.files-block.sender .files-btns a, .files-block.sender .files-btns button {
    color: rgba(255, 255, 255, 0.5);
}

.files-block .files-btns a:hover, .files-block .files-btns button:hover {
    color: #05728f;
}

.files-block.sender .files-btns a:hover, .files-block.sender .files-btns button:hover {
    color: #FFF;
}

.files-block .files-btns button:focus {
    outline: 0;
}

/* add member modal */

.addmember-modal .modal-title {
    margin-bottom: 0;
}

.btn-modal-close {
    background: transparent;
    border: 0;
}

.addmember-modal .modal-body {
    padding: 0;
}

.form_block {
    max-width: 300px;
    margin: 0 auto;
    padding: 65px 0;
}

.block-members {
    width: 100%;
    display: inline-block;
    position: relative;
}

.block-members .search-wrp {
    position: relative;
    width: 100%;
    display: inline-block;
}

.block-members .search-wrp input {
    width: 100%;
    height: 50px;
    border: 0;
    background: #ebebeb;
    padding: 10px 15px 10px 45px;
}

.block-members .search-wrp input:focus {
    outline: unset;
}

.block-members .search-wrp .icon {
    position: absolute;
    top: 50%;
    left: 15px;
    font-size: 20px;
    transform: translate(0, -50%);
}

.selected-member {
    padding: 15px 0;
    border-bottom: 1px solid #c4c4c4;
    margin-bottom: 0;
    width: 100%;
    overflow: hidden;
    overflow-x: auto;
    list-style: none;
}

.selected-member li {
    list-style: none;
    display: inline-block;
    position: relative;
    padding: 0 15px;
}

.selected-member li {
    float: left;
}
.selected-member li .member-select .image-with-button{
    position: relative;
    width: 65px;
    height: 65px;
    margin: 0 auto;
}
.selected-member li .member-select .image-wraps {
    width: 65px;
    height: 65px;
    overflow: hidden;
    border-radius: 50%;
    margin: 0 auto;
}

.selected-member li .member-select .image-wraps img {
    width: 100%;
    object-fit: cover;
}

.selected-member li .member-select .image-wraps .shape {
    width: 100%;
    height: 100%;
    background: #c4c4c4;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-weight: 500;
    color: #464646;
}

.selected-member li .member-select p {
    font-size: 12px;
    text-align: center;
    margin-bottom: 0;
    color: #464646;
    font-weight: 400;
}

.selected-member li .btn-delete-member {
    background: #05728f;
    color: #FFF;
    border: 2px solid #FFF;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 25px;
    height: 25px;
    border-radius: 50%;
    font-size: 13px;
    position: absolute;
    right: 0px;
    top: -2px;
    padding: 0;
}

.list-member {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 0;
    max-height: 300px;
    overflow: hidden;
    overflow-y: auto;
    list-style: none;
    margin-bottom: 0;
}

.list-member li {
    list-style: none;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    padding: 15px;
    border-bottom: 1px solid #c4c4c4;
    cursor: pointer;
}

.list-member li:last-child {
    border-bottom: 0;
}

.list-member li:hover {
    background: #f7f7f7;
}

.list-member li .member-wrap {
    width: calc(100% - 50px);
    display: flex;
    align-items: center;
    justify-content: flex-start;
}

.list-member li .member-wrap .member-image {
    width: 40px;
    height: 40px;
    display: inline-block;
    position: relative;
    border-radius: 50%;
    overflow: hidden;
    margin-right: 10px;
}

.list-member li .member-wrap .member-image .img-member {
    width: 100%;
    object-fit: cover;
}

.list-member li .member-wrap .member-image .shape {
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #c4c4c4;
    font-size: 14px;
    font-weight: 500;
}

.list-member li .member-wrap .member-title h3 {
    font-size: 16px;
    font-weight: 400;
    margin-bottom: 0;
    color: #0F1626;
}

.list-member li .member-wrap .member-title p {
    font-size: 12px;
    color: #707070;
    margin-bottom: 0;
}

.list-member li .checkbox-shape {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #c4c4c4;
    border-radius: 50%;
    font-size: 15px;
    background: transparent;
}

.list-member li .checkbox-shape svg {
    display: none;
}

.list-member li.active .checkbox-shape {
    background: #05728f;
    color: #FFF;
    border-color: #05728f;
}

.list-member li.active .checkbox-shape svg {
    display: block;
}

/* add member modal over */

@media screen and (max-width:576px) {
    .headind_srch {
        display: none;
    }
    .inbox_chat {
        max-height: calc(100vh - 55px);
    }
    .chat_list {
        padding: 18px 8px 10px;
    }
    .chat_ib {
        display: none;
    }
    .received_withd_msg {
        width: calc(100% - 25px);
    }
    .incoming_msg {
        display: flex;
        align-items: flex-start;
        justify-content: flex-start;
    }
    .div_limit {
        max-width: calc(100% - 25px);
    }
    .files-block .fileandName {
        width: 100%;
    }
}