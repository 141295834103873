.card_wrap {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
    position: relative;
}
.profileEditBtn{
    position: absolute;
    right: 20px;
    background: transparent;
    border: 1px solid #0F1626;
    border-radius: 25px;
    padding: 5px 10px;
    font-size: 13px;
    line-height: 1.5;
    display: flex;
    align-items: center;
}
.profileEditBtn svg{
    margin-right: 5px;
}
.img_wrap2{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin: 25px auto 15px;
    position: relative;
}
.img_wrap2 img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.img_wrap{
    width: 150px;
    height: 150px;
    border-radius: 50%;
    /* overflow: hidden; */
    margin: 25px auto 15px;
    position: relative;
}
.img_wrap .img_block {
    width: 150px;
    height: 150px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0px auto;
    position: relative;
}

.img_wrap .img_block img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}
.btn_fileUpload{
    background: #0F1626;
    color: #FFF;
    font-size: 15px;
    border: 0;
    position: absolute;
    bottom: 0;
    right: 24px;
    width: 25px;
    height: 25px;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 50%;
    z-index: 1;
}

.user_content {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 15px;
}

.user_content h3 {
    font-size: 25px;
    line-height: 35px;
    font-family: 'Roboto', sans-serif;
    font-weight: 600;
    color: #262b23;
    margin-bottom: 0;
}

.user_content .nick_nm {
    /* color: #8b8b8b; */
    color: var(--grayWeb);
    font-family: 'Roboto', sans-serif;
    font-size: 18px;
    font-weight: 400;
    margin-bottom: 20px;
}

.rating_block {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    margin-bottom: 1rem;
}

.rating_block p {
    display: inline-block;
    /* background: #ffc12d; */
    background: var(--cyberYellow);
    border-radius: 4px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    padding: 2px 7px;
    margin-bottom: 0;
    font-family: 'Roboto', sans-serif;
}

.rating_block .review_text {
    font-size: 15px;
    font-weight: 700;
    font-family: 'Roboto', sans-serif;
    color: #000;
    margin-left: 5px;
}

.star_ratings {
    margin-left: 15px;
}

.right_sec {
    padding: 20px 20px 30px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
    position: relative;
    margin-bottom: 50px;
    margin-top: -150px;
}
.right_sec.mt_0{
    margin-top: 0;
}
.private_Info {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 15px;
}

.private_Info h4 {
    color: var(--richBlack);
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 30px;
    font-family: 'Roboto', sans-serif;
}

.private_Info .uploadImg {
    width: 100%;
    max-width: 75px;
    display: block;
    margin: 0 auto 35px;
    position: relative;
}

.btn_edit {
    position: absolute;
    bottom: 5px;
    left: 40%;
    width: 20px;
    height: 20px;
    margin-left: 20px;
    z-index: 1;
    /* background: transparent; */
    border: none;
    padding: 0;
    outline: none;
    box-shadow: none;
    background-color: var(--bs-gray);
    border-radius: 50%;
    color: #FFF;
    font-size: 12px;
}

.client_profile {
    width: 75px;
    height: 75px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: 50%;
}

.client_profile img {
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.phone_container {
    width: 100%;
}

.profile_modal .Upload_wrap {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 15px;
}

.profile_modal .Upload_wrap .input_wrap {
    width: 100%;
    display: inline-block;
    position: relative;
}

.profile_modal .Upload_wrap .input_wrap input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100%;
    /* display: none; */
}

.profile_modal .Upload_wrap .input_wrap label {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    border: 2px dashed var(--cyberYellow);
    /* border:2px dashed #1a62f5; */
    height: 250px;
    border-radius: 3px;
    cursor: pointer;
}

.profile_modal .Upload_wrap .input_wrap label .icon_img {
    display: block;
    margin: 0 auto 5px;
    font-size: 28px;
    color: var(--cyberYellow);
    /* color: #1a62f5; */
}

.profile_modal .Upload_wrap .input_wrap label p {
    margin: 15px 0 0;
    font-family: 'Roboto', sans-serif;
    font-weight: 500;
    font-size: 14px;
    line-height: 26px;
    color: var(--grayWeb);
    /* color: #000; */
    text-align: center;
}

.profile_modal .Upload_wrap .input_wrap label p span {
    font-weight: 600;
    color: var(--cyberYellow);
    /* color: #1a62f5; */
}

.profile_modal .Upload_wrap .uploaded_wrap {
    width: 300px;
    display: block;
    position: relative;
    margin: 0 auto;
}

.profile_modal .Upload_wrap .uploaded_wrap .image_wrap {
    width: 100%;
    height: 300px;
    display: inline-block;
    position: relative;
    overflow: hidden;
    border-radius: 5px;
    border: 1px solid #e0e0e0;
}

.profile_modal .Upload_wrap .uploaded_wrap .image_wrap img {
    width: 100%;
    /* height: 100%; */
    object-fit: cover;
}

.imgDelet {
    width: 25px;
    height: 25px;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: -10px;
    bottom: 0;
    border-radius: 50%;
    border: 0;
    background: #f51a1a;
    color: #FFF;
    font-size: 15px;
    cursor: pointer;
}

.modal_close {
    background: transparent;
    border: 0;
    /* font-size: 18px; */
}

.location {
    position: relative;
    display: flex;
    align-items: center;
    margin-bottom: 0;
    margin: 15px 0 30px !important;
    color: #0F1626 !important;
    font-size: 14px !important;
    font-weight: 500 !important;
    font-family: 'Noto Sans JP', sans-serif !important;
}

.location img {
    width: 30px;
    height: 21px;
    display: inline-block;
    margin-right: 15px;
    border-radius: 3px;
}

.activeted_ids {
    padding-left: 0;
    list-style: none;
    border-bottom: 2px solid #f0f0f0;
    margin-bottom: 25px;
}

.activeted_ids li {
    display: flex;
    align-items: center;
    margin-bottom: 20px;
    position: relative;
}

.activeted_ids li .icon {
    height: 24px;
    width: 24px;
    margin-right: 15px;
    background: #c1c1c1;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.activeted_ids li .icon svg {
    max-height: 10px;
}

.activeted_ids li span {
    color: #a7a7a7;
    font-size: 14px;
    font-weight: 500;
    font-family: 'Noto Sans JP', sans-serif;
}

.activeted_ids li.active .icon {
    background: #1a62f5;
}

.activeted_ids li.active .span {
    color: #0F1626;
}

.job_ratio {
    color: #0F1626;
    font-weight: 500;
    font-size: 14px;
    margin-bottom: 18px;
    font-family: 'Noto Sans JP', sans-serif;
}

.job_ratio b {
    color: #1a62f5;
    font-size: 15px;
}

.date_time p {
    font-family: 'Noto Sans JP', sans-serif;
    color: #0F1626;
    font-weight: 500;
    font-size: 14px;
    position: relative;
    display: flex;
    align-items: center;
}

.date_time p:before {
    content: "";
    width: 15px;
    height: 15px;
    display: inline-block;
    margin-right: 15px;
    background-position: 50%;
    background-repeat: no-repeat;
    background-size: 15px;
}

.date_time .time:before {
    background-image: url('../images/clock.svg');
}

.date_time .meeting:before {
    background-image: url('../images/calendars-icon.svg');
}

.date_time .date:before {
    background-image: url('../images/badge.svg');
    height: 20px;
}

.date_time .email:before {
    background-image: url('../images/email-ic.svg');
    height: 20px;
}

.date_time .website:before {
    background-image: url('../images/web.svg');
    height: 20px;
}

.date_time .mobile:before {
    background-image: url('../images/phone.svg');
    height: 20px;
}

.date_time .address {
    word-break: break-all;
    align-items: baseline;
    padding-left: 32px;
}

.date_time .address:before {
    background-image: url('../images/location-pin.svg');
    height: 20px;
    position: absolute;
    left: 0;
}

.earning {
    padding: 20px 0 0;
    border-top: 2px solid #f0f0f0;
    display: flex;
    justify-content: space-between;
    margin: 0 -3px;
    font-family: 'Noto Sans JP', sans-serif;
}

.earning>div {
    display: flex;
    flex-direction: column;
    padding: 0 3px;
    align-items: center;
}

.earning h5 {
    font-size: 16px;
    font-weight: 600;
    color: #000;
}

.earning span {
    font-size: 12px;
    letter-spacing: .57px;
    line-height: 17px;
    font-weight: 400;
    color: #0F1626;
}

.partition {
    display: flex;
    flex-direction: column;
    width: 100%;
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px;
    padding-bottom: 20px;
    font-family: 'Noto Sans JP', sans-serif;
}
.partition:last-child {
    padding-bottom: 0;
    margin-bottom: 0;
    border: none;
}

.cap_hire_div {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 10px;
}

.cap_hire_div .title_cap {
    font-size: 24px;
    font-weight: 600;
    color: #0F1626;
    position: relative;
    display: inline-block;
    margin-bottom: 0;
    /* width: calc(100% - 84px); */
}
.cap_hire_div.hover_div{
    position: relative;
}
.cap_hire_div.hover_div button{
    /* display: none; */
    position: absolute;
    right: 0px;
    background: transparent;
    border:1px solid #0F1626;
    border-radius: 25px;
    padding: 5px 10px;
    font-size: 13px;
    line-height: 1;
}
/* .cap_hire_div.hover_div:hover button{
    display: block;
    position: absolute;
    right: 0px;
} */

.description_ti {
    font-size: 14px;
    font-weight: 400;
    color: #0F1626;
}

.partition h5 {
    font-weight: 600;
    font-size: 18px;
    color: #0F1626;
    margin-bottom: 20px;
    position: relative;
}
.partition.partition_hover h5{
    line-height: 1.5;
}
.partition.partition_hover h5 .btn_added{
    position: absolute;
    right: 0px;
    background: transparent;
    border:1px solid #0F1626;
    border-radius: 25px;
    padding: 5px 10px;
    font-size: 13px;
    line-height: 1;
}

.portfolio_listing {
    padding-left: 0;
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;
    max-height: 350px;
    /* min-height: 350px; */
    overflow: hidden;
    overflow-y: auto;
    transition: all .3s ease;
    width: 100%;
}

.portfolio_listing li {
    width: 33.33%;
    float: left;
    padding: 0 20px;
    cursor: pointer;
    margin-bottom: 40px;
    list-style: none;
}
.portfolio_listing li.noPortfolio{
    width: 100%;
    float: unset;
    margin-bottom: 0;
    color: rgba(0, 0, 0, .5);
}
.portfolio_listing li.noPortfolio b{
    cursor: pointer;
    color: #0d6efd;
}

.portfolio_listing li .effect {
    overflow: hidden;
    position: relative;
    object-fit: cover;
    margin: 0 auto;
    border-radius: 7px;
    border: 1px solid #f0f0f0;
    width: 100%;
    height: 170px;
}

.portfolio_listing li img {
    width: 100%;
    height: 100%;
    object-fit: cover;
    transition: transform 1s;
}

.effect .contentPart {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 34px;
    background-color: rgba(0, 0, 0, .7);
    opacity: 1;
    transition: all 1s;
    -webkit-transition: all 1s;
    -moz-transition: all 1s;
    transform: translateY(34px);
    -webkit-transform: translateY(34px);
    -moz-transform: translateY(34px);
    transform-origin: 0 0;
    display: flex;
    align-items: center;
}
.effect.freelancer_effect .contentPart{
    height: 100%;
    transform: translateY(100%);
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    transform-origin: 0 0;
    justify-content: center;
    flex-direction: column;
    transition: all 0.5s;
    -webkit-transition: all 0.5s;
    -moz-transition: all 0.5s;
}

.effect .contentPart span {
    color: #fff;
    font-weight: 500;
    font-size: 12px;
    display: flex;
    width: 100%;
    /* height: 100%; */
    align-items: center;
    padding: 0 10px;
}
.effect.freelancer_effect .contentPart span{
    text-align: center;
    width: 80%;
    display: block;
    position: relative;
    padding: 10px;
    border-bottom: 1px solid rgb(255 255 255 / 25%);
    margin-bottom: 10px;
}

.effect:hover .contentPart {
    transform: translateY(0);
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
}
.effect.freelancer_effect .contentPart button{
    background: transparent;
    border: 0;
    padding: 0;
    color: #FFF;
    font-size: 15px;
    /* margin: 0 5px; */
    padding: 5px;
    border-right: 1px solid rgb(255 255 255 / 20%);
}
.effect.freelancer_effect .contentPart button:last-child{
    border-right: 0;
}
.effect.freelancer_effect .contentPart button:hover{
    color: #FFCE00;
}
.loader_text{
    display: inline-block;
    width: 100%;
    text-align: center;
    font-size: 14px;
    margin-bottom: 0;
}
.rounded {
    border-radius: .25rem!important;
}
.partition .rounded {
    margin: 0px;
    overflow: hidden;
    padding-left: 0;
}
.partition .rounded.expertise, 
.partition .rounded.language {
    display: contents;
    
}
.partition .rounded li {
    display: inline-block;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    border-radius: 100px;
    /* border: 1px solid #000; */
    background: #eaeaea;
    padding: 4px 10px;
    line-height: 20px;
    float: left;
    margin: 10px 3px;
}
.partition .rounded.expertise li, 
.partition .rounded.language li {
    border: 0;
    margin: 0;
    font-weight: 600;
    background: transparent;
}
.partition .rounded li span {
    font-weight: 400;
    color: #1c2740;
    margin-left: 3px;
}
.btn_viewMore {
    background-color: transparent;
    border: 0;
    display: block;
    /* color: #000; */
    font-weight: 600;
    color: #1a62f5;
    font-size: 15px;
    margin: 0 18px;
}
.btn_viewMore.skills_more {
    margin: 10px 18px;
}
.partition .rounded.less {
    height: 50px;
}
.partition .rounded.more {
    height: auto;
}
.review_wrap h4{
    font-family: 'Noto Sans JP', sans-serif;
}
.review_wrap h4{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 30px;
}
.review_ul{
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.review_ul .review_div {
    display: inline-block;
    width: 100%;
    border-bottom: 1px solid #ededed;
    margin-bottom: 20px;
    padding-bottom: 15px;
}
.review_ul .review_div .caption_wrap{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 15px;
}
.review_ul .review_div .caption_wrap .review_title{
    font-size: 16px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 20px;
    word-break: break-all;
    color: #1a62f5;
}
.review_div .time {
    font-size: 12px;
    /* color: #cecece; */
    color: #989898;
    font-weight: 500;
    line-height: normal;
}
.review_div .rating.rvi {
    margin-bottom: 10px;
    width: 100%!important;
    align-items: center;
    display: flex!important;
}
.review_div .rating_badge {
    font-size: 12px;
    padding: 2px 5px;
    width: auto;
    margin-bottom: 0;
    border-radius: 3px;
    color: #fff!important;
    display: inline-block;
    background: #ffc12d;
    letter-spacing: .12px;
    margin-right: 5px;
}
.review_div .detail p{
    font-size: 12px;
    font-weight: 500;
    letter-spacing: .12px; 
    color: #000;
    margin-bottom: 18px;
    word-break: break-all;
}
.review_div:last-child, 
.review_div:last-of-type {
    border-bottom: 0;
    padding: 0;
}

/* pagination */

.pagination_wrap {
    /* padding-top: 1rem; */
    margin-top: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 30px 0 10px;
    border-top: 1px solid #ededed;
}

.pagination_wrap>ul {
    padding: 0;
    margin: 0;
}

.pagination_wrap li {
    display: inline-block;
    margin: 0 2px;
}

.pagination_wrap li.previous {
    margin: 0 15px 0 0px;
}

.pagination_wrap li.next {
    margin: 0 0px 0 15px;
}

.pagination_wrap li>a {
    padding: 2px 6px;
    margin: 1px;
    width: 32px;
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    min-width: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #0F1626;
}

.pagination_wrap li>a:hover {
    background: #f5f5f5;
}

.pagination_wrap li>a:focus {
    outline: none;
}

.pagination_wrap li.active>a {
    background: #0F1626;
    border-color: #0F1626;
    color: #FFF;
}

.pagination_wrap li.active>a:hover {
    background: #0F1626;
    border-color: #0F1626;
    color: #FFF;
}

/* pagination over */
/* file custom css */
.file_input_wrap{
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 20px 0;
}
.file_input_wrap input[type="file"]{
    visibility: hidden;
    opacity: 0;
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
}
.file_input_wrap .file_label2{
    width: 100%;
    height: 150px;
    display: flex;
    align-items: center;
    justify-content: center;
    border:2px dashed #ced4da;
    border-radius: .25rem;
    /* opacity: 0.5; */
    font-size: 18px;
    color: #0F1626;
    margin-bottom: 0;
    cursor: pointer;
}
.fileDisplay{
    width: 100%;
    display: inline-block;
    padding-left: 0;
    margin-bottom: 0;
}
.fileDisplay li{
    width: 80px;
    overflow: hidden;
    display: inline-block;
    border-radius: 5px;
    list-style: none;
    position: relative;
    margin: 15px 15px 0px 0;
}
.fileDisplay li .img_ul_wrap{
    width: 100%;
    max-width: 80px;
    height: 80px;
    overflow: hidden;
}
.fileDisplay li .img_ul_wrap img{
    width: 100%;
    object-fit: cover;
}
.fileDisplay li .image_overlay{
    width: 80px;
    height: 80px;
    position: absolute;
    /* top: 0; */
    bottom: -85px;
    left: 0;
    right: 0;
    background: rgb(0 0 0 / 50%);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s all;
}
.fileDisplay li .delete_btns{
    font-size: 12px;
    width: 25px;
    height: 25px;
    padding: 5px 7px;
    border-radius: 25px;
    color: #FFF;
    background: #f51a1a;
    border:0;
    display: flex;
    align-items: center;
    justify-content: center;
}
.fileDisplay li:hover .image_overlay{
    bottom: 0;
}
/* file custom css over */

/* title overview */
.contact_label{
    font-size: 15px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px;
    display: block;
}
.info_textbox_text {
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 500;
    color: #0F1626;
    margin-top: 5px;
    text-align: right;
    opacity: 0.7;
    font-family: 'Roboto', sans-serif;
}
/* title overview over */
/* service */
.form_group {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 30px;
}

/* .form_group:last-child {
    margin-bottom: 0;
} */

.form_group.max_500 {
    max-width: 500px;
}

.form_group label {
    font-size: 15px;
    font-weight: 600;
    color: #0F1626;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px;
}
/* service over */

/* profile photo */
.body_title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #0F1626;
}
.file_wrap {
    width: 100%;
    max-width: 180px;
    height: 180px;
    display: inline-block;
    margin: 15px 0;
    position: relative;
}

.file_wrap input[type="file"] {
    opacity: 0;
    position: absolute;
    top:0;
    left: 0;
    bottom: 0;
    right: 0;
    cursor: pointer;
    visibility: unset !important;
}
.file_wrap .file_label{
    width: 100%;
    height: 180px;
    border:2px dashed rgba(0 0 0 / 15%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 500;
    color: rgba(0 0 0 / 45%);
    cursor: pointer;
}
.file_wrap .file_label i{
    margin-bottom: 15px;
    font-size: 75px;
}
.image_wap{
    width: 100%;
    max-width: 180px;
    height: 180px;
    text-align: center;
}
.image_wap .image{
    /* width: 100%; */
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}
.image_wap .image img{
    width: 100%;
    object-fit: cover;
}
.image_wap .delete_btn{
    background: transparent;
    border:1px solid #D32F2F;
    color: #D32F2F;
    padding: 5px 15px;
    font-family: 'Noto Sans JP', sans-serif;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 14px;
    cursor: pointer;
}
/* profile photo over */

@media screen and (max-width:1200px) {
    .right_sec {
        margin-top: -100px;
    }
    .right_sec.mt_0{
        margin-top: 0;
    }
}

@media screen and (max-width:991px) {
    .right_sec {
        margin-top: -50px;
    }
    .right_sec.mt_0{
        margin-top: 0;
    }
    .user_content {
        text-align: center;
    }
    .rating_block {
        display: block;
        align-items: unset;
        justify-content: unset;
    }
    .rating_block p, .rating_block .rating_bl {
        display: inline-block;
        float: left;
    }
    .rating_block .rating_bl {
        width: calc(100% - 50px);
    }
    .review_text {
        display: block;
        width: 100%;
    }
    .portfolio_listing li{
        width: 50%;
        padding: 0 10px;
        margin-bottom: 20px;
    }
    .review_div .rating.rvi{
        align-items: flex-start;
    }
}

@media screen and (max-width:767px) {
    .right_sec {
        margin-top: 0px;
    }
    .right_sec.mt_0{
        margin-top: 0;
    }
    .card_wrap {
        margin-bottom: 25px;
    }
}

@media screen and (max-width:576px) {
    .rating_block {
        display: flex;
        align-items: center;
        justify-content: flex-start;
    }
    .rating_block .rating_bl {
        width: initial;
    }
    .portfolio_listing li{
        width: 100%;
    }
}
@media screen and (max-width:320px) {
    .rating_block .review_text{
        font-size: 12px;
    }
    .rating_block .rating_bl {
        width: calc(100% - 10px);
    }
}