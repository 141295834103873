.width_776{
    max-width: 776px !important;
}
.authWrap{
    width:100%;
    display: flex;
    align-items: center;
    margin: 56px 0;
    padding: 50px 0;
}
.authWrapLog{
    width:100%;
    /* height: 80vh; */
    display: flex;
    align-items: center;
    margin: 56px 0;
    padding: 50px 0;
    /* padding-bottom: 50px; */
}
.headerAuth{
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: center;
}
.authTitle{
    font-family: 'Roboto', sans-serif;
    font-size: 40px;
    line-height: 1.5;
    font-weight: 300;
    color: var(--blue);
    margin-bottom: 25px;
}
.subtitleLog{
    font-family: 'Roboto', sans-serif;
    font-size: 16px;
    line-height: 1.8;
    font-weight: 400;
    color: var(--richBlack);
    margin-bottom: 0px;
}
.header_login{
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 16px;
}
.header_login .title{
    font-family: 'Roboto', sans-serif;
    font-size: 52px;
    line-height: 65px;
    font-weight: 300;
    color: #25313c;
    /* color: var(--richBlack); */
    margin: 0px;
}
.header_login .title2{
    font-family: 'Roboto', sans-serif;
    font-size: 43px;
    line-height: 58px;
    font-weight: 300;
    color: #25313c;
    /* color: var(--richBlack); */
    margin: 0px 0 30px;
    text-align: center;
}
.header_login .subTi{
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    line-height: 18x;
    font-weight: 300;
    color: #455065;
    text-align: center;
    margin: 0px 0 30px;
}
.reset_bg{
    background: var(--white);
    padding: 35px 25px !important;
    border-radius: 8px;
}
.form_wrap{
    width: 100%;
    max-width: 500px;
    margin: 0 auto;
    padding-right: 35px;
    border-right: 1px solid #e0e0e0;
}
.terms{
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 12px;
    /* color: #696969; */
    color: var(--grayWeb);
    line-height: 18px;
    font-weight: 400;
    margin-bottom: 0px;
}
.terms a{
    text-decoration: underline;
    /* color: var(--blue); */
}
.typeJob{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
}
.typeJob input[type="radio"]{
    display: none;
}
.typeJob label{
    width: 100%;
    display: inline-block;
    position: relative;
    text-align: center;
    border:1px solid #ced4da;
    background-color: var(--white);
    padding: 6px 10px;
    color: #6c757d;
    border-radius: 5px;
    cursor: pointer;
    font-family: 'Roboto', sans-serif;
}

.typeJob label:nth-child(2){
    border-radius: 5px 0px 0px 5px;
}
.typeJob label:last-child{
    border-left: 0;
    border-radius: 0px 5px 5px 0px;
}
.typeJob input[type="radio"]:checked + label{
    background: var(--blue);
    border-color: var(--blue);
    color: var(--white);
}
.typeJob label.error{
    border-color: var(--errorColor);
}
.supportWrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    /* border-left: 1px solid #e0e0e0; */
    padding-left: 35px;
    flex-direction: column;
}
.supportWrap .titleTip{
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 300;
    font-size: 15px;
    color: #455065;
    text-align: center;
    margin-top: 2px;
    margin-bottom: 40px;
}
.ulWrap{
    width: 100%;
    display: inline-block;
    position: relative;
    padding-left: 0;
    margin-bottom: 0;
    list-style: none;
}
.ulWrap li{
    text-align: center;
    height: 48px;
    line-height: 48px;
    /* margin-bottom: 54px; */
    width: 160px;
    display: block;
    margin: 0 auto 40px;
}
.ulWrap li:last-child{
    margin-bottom: 0
}
.content_account{
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 30px 0 0;
    text-align: center;
}
.content_account p{
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    color: var(--richBlack);
    font-weight: 400;
    line-height: 1.5;
}
.content_account p a{
    color: #25313c;
    /* color: var(--blue); */
    font-weight: 600;
}
.content_account .copyRigt{
    width: 100%;
    display: block;
    font-family: 'Roboto', sans-serif;
    font-size: 13px;
    color: #000;
}
.dropdown_li a{
    cursor: pointer;
}
.dropdown_li:hover .dropdown_menu{
    display: block;
}
.dropdown_menu li:hover{
    color: #1e2125;
    background-color: #e9ecef;
}
.alert_notification{
    position: absolute;
    top: 15px;
    right: 7px;
    padding: 0.25rem;
    border-radius: 50%;
    border:1px solid #FFF;
}
@media screen and (max-width:576px) {
    .authTitle{
        font-size: 30px;
    }
    .subtitleLog{
        font-size: 14px;
    }
    .form_wrap{
        padding-right:calc(var(--bs-gutter-x)/ 2);
    }
    .typeJob label{
        font-size: 13px;
        padding: 8px 8px;
    }
    .supportWrap{
        padding-left: calc(var(--bs-gutter-x)/ 2);
        margin-top: 35px;
    }
    .mobile-dropdown .dropdown-menu{
        box-shadow: none !important;
        border:0;
        background: transparent;
        padding-left: 15px;
    }
}