.section_job_details {
    padding: 55px 0;
}

.icon_back {
    font-size: 25px;
}

.btn_closeJobs {
    background: 0 0;
    border: none;
    color: red;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 600;
    font-size: 15px;
    text-decoration: underline;
}

.tabs_list {
    width: 100%;
    padding: 0;
    list-style: none;
    display: flex;
    margin-bottom: 40px;
    border-bottom: 1px solid transparent;
    position: relative;
}

.tabs_list li {
    padding: 0 0 8px;
    text-align: center;
    position: relative;
    margin-right: 20px;
}

.tabs_list li button {
    font-size: 16px;
    font-weight: 500;
    color: #1c2740;
    padding: 0 10px;
    font-family: 'Noto Sans JP', sans-serif;
    background-color: transparent;
    border: 0;
    box-shadow: unset;
}

.tabs_list li.active button {
    color: var(--cyberYellow);
    /* color: #1a62f5; */
}

.tabs_list li.active button:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    /* background: #1a62f5; */
    background: var(--cyberYellow);
    width: 100%;
    height: 2px;
}

/* details page css */

.title_listing {
    font-family: 'Noto Sans JP', sans-serif;
    font-size: 22px;
    font-weight: 700;
    color: #000;
    line-height: 26px;
    margin-bottom: 30px;
    display: flex;
    justify-content: space-between;
}

.title_listing .job_ids {
    color: #646464;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: .32px;
}

.fill_wrap {
    width: 100%;
    max-width: 540px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    background: #fbfbfb;
    border-radius: 5px;
    padding: 20px 30px;
    font-family: 'Noto Sans JP', sans-serif;
}

.fill_wrap.full {
    max-width: 100%;
}

.fill_wrap .label {
    font-size: 16px;
    font-weight: 400;
    color: #898989;
    line-height: 19px;
    letter-spacing: 0;
    text-align: left;
    margin-bottom: 10px;
}

.fill_wrap p {
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    line-height: 32px;
    letter-spacing: .36px;
    color: #000;
    text-align: left;
}

.fill_wrap .file_name {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
}

.fill_wrap .file_name li {
    width: 100%;
    display: flex;
    align-items: center;
    margin-bottom: 15px;
}

.fill_wrap .file_name li:last-child {
    margin-bottom: 0;
}

.fill_wrap .file_name li span {
    color: #000;
    letter-spacing: .24px;
    font-size: 14px;
    line-height: 24px;
    text-align: left;
    font-weight: 600;
    word-break: break-all;
}

.fill_wrap .file_name li .attchment_icon {
    /* color: #000; */
    font-size: 22px;
    margin-right: 10px;
}

.fill_wrap .file_name li .view_filebtn {
    background: #f2f2f2;
    width: 30px;
    display: flex;
    height: 30px;
    align-items: center;
    justify-content: center;
    margin-left: 12px;
}

/* details css over */

/* propsals css */

.freelancer_list_wrap {
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 0;
    margin-bottom: 0;
}

/* .freelancer_list_wrap li{
    list-style: none;
} */

.freelancer_list_wrap li {
    width: 100%;
    display: flex;
    margin-bottom: 30px;
    position: relative;
    /* box-shadow: 0 6px 20px rgb(26 98 245 / 10%); */
    border-radius: 0px;
    padding: 20px 30px;
    /* cursor: pointer; */
    list-style: none;
    border-bottom: 1px solid #dee2e6;
}
.freelancer_list_wrap li:hover{
    background: #f9f9f9;
}

.freelancer_list_wrap li:last-child {
    margin-bottom: 10px;
    border-bottom: 0;
}

.user_prof {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    overflow: hidden;
}

.user_prof img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.right_detail {
    width: calc(100% - 80px);
    padding-left: 23px;
    font-family: 'Noto Sans JP', sans-serif;
}

.profile_wrap {
    display: flex;
    width: 100%;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 10px;
    cursor: pointer;
}

.caption {
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.review_title {
    font-size: 22px;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 20px;
    letter-spacing: .2px;
    color: #1a62f5;
}

.rating {
    display: flex!important;
    width: auto!important;
    align-items: center;
    margin-right: 18px;
}

.rating_badge {
    display: inline-block;
    background: #ffc12d;
    border-radius: 6px;
    color: #fff;
    font-size: 15px;
    font-weight: 700;
    padding: 2px 7px;
    margin-right: 5px;
    margin-bottom: 0;
}

.rating span {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-left: 5px;
}

.user_review {
    width: 100%;
    margin-bottom: 25px;
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.user_r_detail {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    line-height: 0;
}

.user_r_detail img {
    width: 28px;
    height: 17px;
    display: inline-block;
    margin: 0 10px 0 0;
    border-radius: 3px;
}

.user_r_detail p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #000;
}

.price {
    font-size: 16px;
    font-weight: 600;
    color: #000;
    margin-bottom: 0;
}

.price .pric {
    width: 100%;
    display: inline-block;
}

.price .deadline {
    width: 100%;
    display: inline-block;
    font-size: 14px;
    color: #898989;
    font-weight: 500;
}

.detail {
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 0 0 40px;
    font-size: 16px;
    color: #000;
}
.detail p{
    font-family: 'Noto Sans JP', sans-serif;
    display: -webkit-box;
    display: -moz-box;
    display: -o-box;
    display: -ms-box;
    overflow: hidden;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    -moz-line-clamp: 2;
    -o-line-clamp: 2;
    -o-box-orient: vertical;
    -ms-line-clamp: 2;
    -ms-box-orient: vertical;
    font-size: 15px;
}

.bottom_freela {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.btns {
    display: flex;
    align-items: center;
}

.btns1 {
    height: 55px;
    width: 237px;
    border-radius: 5px;
    background: transparent;
    max-width: 110px;
    font-size: 14px;
    font-weight: 500;
    max-height: 30px;
    line-height: 16px;
    margin-left: 20px;
    box-shadow: none;
    display: inline-block;
    cursor: pointer;
}

.btns1.btn_default {
    border: 1px solid #000;
    color: #000;
}

.btns1.btn_blue {
    background-color: #1a62f5;
    border: 1px solid #1a62f5;
    color: #fff;
}

.pricing_block {
    display: flex;
    width: 100%;
    position: relative;
    align-items: center;
    justify-content: flex-end;
    margin: 40px 0;
    font-family: 'Noto Sans JP', sans-serif;
}

.pricing_block label {
    margin-bottom: 0;
    margin-right: 30px;
    font-family: "Roboto", sans-serif;
    font-size: 18px;
    font-weight: 500;
    color: #000;
    letter-spacing: .36px;
    line-height: 21px;
    text-align: left;
}

.pricing_block .input_di {
    width: 100%;
    max-width: 150px;
    border: 1px solid #d9d9d9;
    background: #fbfbfb;
    position: relative;
    display: inline-block;
}

.pricing_block .input_di span {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    font-size: 20px;
    padding: 5px 13px;
    border-right: 1px solid #d9d9d9;
    font-weight: 600;
    color: #000;
}

.pricing_block .input_di input[type="text"] {
    width: 100%;
    height: 40px;
    border: 0;
    text-align: center;
    font-size: 18px;
    color: #000;
    font-weight: 600;
    padding-left: 40px;
    font-family: "Roboto", sans-serif;
}

.pricing_block .input_di input[type="text"]:focus {
    outline: 0;
}

.errorText {
    /* color: #f55; */
    margin-bottom: 0;
    position: absolute;
    bottom: 0;
    font-size: 14px;
    bottom: inherit;
    top: 100%;
}
.fre_wrap {
    width: 100%;
    min-height: 235px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-end;
}
.fre_wrap .choo_btn, .fre_wrap p {
    font-size: 16px;
    line-height: 19px;
    letter-spacing: .16px;
    text-align: center;
    font-family: 'Noto Sans JP',sans-serif;
}
.fre_wrap p {
    font-weight: 600;
    color: #e5e5e5;
    margin-bottom: 20px;
}
.fre_wrap .choo_btn {
    background: #fff;
    border-radius: 5px;
    box-shadow: 0 3px 10px rgb(26 98 245 / 10%);
    display: inline-block;
    padding: 10px 15px;
    font-weight: 500;
    color: #000;
    border: 0;
}

.freelancer_list_wrap.single_wrap li {
    box-shadow: unset;
    padding: 0;
    border-radius: 0;
    margin-bottom: 0;
}

/* propsals css over*/
/* status Section */
.freelancer_wr{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    background: #fbfbfb;
    border-radius: 5px;
    padding: 35px 30px;
    max-width: 540px;
}
.freelancer_wr .img_wr {
    width: 60px;
    height: 60px;
    border-radius: 50px;
}
.freelancer_wr .img_wr img {
    height: 100%;
    border-radius: 50%;
}
.freelancer_wr .user_name {
    margin-bottom: 0;
    width: 100%;
    padding-left: 30px;
    font-size: 20px;
    font-weight: 600;
    letter-spacing: .2px;
    line-height: 24px;
    color: #1a62f5;
    font-family: 'Noto Sans JP', sans-serif;
}
.freelancer_wr .user_name span {
    margin-top: 10px;
    font-size: 16px;
    font-weight: 600;
    line-height: 24px;
    color: #000;
    letter-spacing: .2px;
}
.freelancer_wr .btn_chat {
    padding: 10px 34px;
    border-radius: 3px;
    display: inline-block;
    background: #0F1626;
    border: 0;
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .14px;
    line-height: 16px;
    text-align: center;
    color: #fff;
}
.step_wrap {
    flex-direction: column;
    padding: 50px 55px;
    background: #fbfbfb;
    border-radius: 5px;
}
.step_wrap, .step_wrap .steps_ul {
    width: 100%;
    display: flex;
    position: relative;
}
.step_wrap .steps_ul {
    align-items: center;
    justify-content: space-between;
    list-style: none;
    padding-left: 0;
    margin-bottom: 0;
}
.step_wrap .steps_ul li {
    position: relative;
}
.step_wrap .steps_ul li .step {
    width: 70px;
    height: 70px;
    border-radius: 50%;
    background: transparent;
    border: 3px solid #d5d5d5;
    text-align: center;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 0 auto;
}
.step_wrap .steps_ul li.stepper__step_done .step {
    border-color: #000;
    background: #000;
}
.step_wrap .steps_ul li .step_title {
    text-align: center;
    margin: 8px 0 0;
    font-size: 20px;
    font-weight: 500;
    line-height: 24px;
    color: #d5d5d5;
    letter-spacing: 0;
    font-family: 'Noto Sans JP', sans-serif;
}
.step_wrap .steps_ul li.stepper__step_current .step_title, 
.step_wrap .steps_ul li.stepper__step_done .step_title {
    color: #000;
}

.step_wrap .steps_ul li.stepper__step_current .step {
    border-color: #000;
    background: transparent;
}

.step_wrap .steps_ul li.stepper__step:after {
    content: "";
    width: 12rem;
    height: 10px;
    position: absolute;
    left: 50%;
    right: 0;
    top: 36px;
    bottom: 0;
    background-image: url('../images/cir-icon.svg');
    background-position: bottom;
    background-size: 11px 5px;
    background-repeat: repeat-x;
    transform: translate(33%,-50%);
    z-index: 0;
    opacity: .5;
}
.step_wrap .steps_ul li.stepper__step:last-child::after{
    display: none;
}
.step_wrap .steps_ul li.stepper__step.stepper__step_done:after {
    opacity: 1;
}

/* .step_wrap .steps_ul li .step svg.submit path#Path_564, 
.step_wrap .steps_ul li .step svg.submit path#Path_565, 
.step_wrap .steps_ul li .step svg.submit path#Path_566, 
.step_wrap .steps_ul li .step svg.submit path#Path_567, 
.step_wrap .steps_ul li .step svg.submit path#Path_568*/
.step_wrap .steps_ul li .step .submit path { 
    fill: #d5d5d5;
    stroke: #d5d5d5;
}

.step_wrap .steps_ul li.stepper__step_current .step svg.deposit path#Path_507, 
.step_wrap .steps_ul li.stepper__step_current .step svg.deposit path#Path_508, 
.step_wrap .steps_ul li.stepper__step_current .step svg.deposit path#Path_509, 
.step_wrap .steps_ul li.stepper__step_current .step svg.deposit path#Path_510, 
.step_wrap .steps_ul li.stepper__step_current .step svg.deposit path#Path_511, 
.step_wrap .steps_ul li.stepper__step_current .step svg.deposit path#Path_512, 
.step_wrap .steps_ul li.stepper__step_current .step svg.deposit path#Path_513, 
.step_wrap .steps_ul li.stepper__step_current .step svg.hired path, 
.step_wrap .steps_ul li.stepper__step_current .step svg.paid path#Path_549, 
.step_wrap .steps_ul li.stepper__step_current .step svg.paid path#Path_550, 
.step_wrap .steps_ul li.stepper__step_current .step svg.paid path#Path_551, 
.step_wrap .steps_ul li.stepper__step_current .step svg.paid path#Path_552, 
.step_wrap .steps_ul li.stepper__step_current .step svg.paid path#Path_553, 
.step_wrap .steps_ul li.stepper__step_current .step svg.paid path#Path_554, 
.step_wrap .steps_ul li.stepper__step_current .step svg.paid path#Path_555, 
.step_wrap .steps_ul li.stepper__step_current .step svg.submit path#Path_564, 
.step_wrap .steps_ul li.stepper__step_current .step svg.submit path#Path_565, 
.step_wrap .steps_ul li.stepper__step_current .step svg.submit path#Path_566, 
.step_wrap .steps_ul li.stepper__step_current .step svg.submit path#Path_567, 
.step_wrap .steps_ul li.stepper__step_current .step svg.submit path#Path_568 {
    fill: #000;
}

.step_wrap .steps_ul li.stepper__step_current .step .paid path{
    fill: #000;
}

.step_wrap .steps_ul li.stepper__step_done .step svg.deposit path#Path_507, 
.step_wrap .steps_ul li.stepper__step_done .step svg.deposit path#Path_508, 
.step_wrap .steps_ul li.stepper__step_done .step svg.deposit path#Path_509, 
.step_wrap .steps_ul li.stepper__step_done .step svg.deposit path#Path_510, 
.step_wrap .steps_ul li.stepper__step_done .step svg.deposit path#Path_511, 
.step_wrap .steps_ul li.stepper__step_done .step svg.deposit path#Path_512, 
.step_wrap .steps_ul li.stepper__step_done .step svg.deposit path#Path_513, 
.step_wrap .steps_ul li.stepper__step_done .step svg.hired path, 
.step_wrap .steps_ul li.stepper__step_done .step svg.paid path#Path_549, 
.step_wrap .steps_ul li.stepper__step_done .step svg.paid path#Path_550, 
.step_wrap .steps_ul li.stepper__step_done .step svg.paid path#Path_551, 
.step_wrap .steps_ul li.stepper__step_done .step svg.paid path#Path_552, 
.step_wrap .steps_ul li.stepper__step_done .step svg.paid path#Path_553, 
.step_wrap .steps_ul li.stepper__step_done .step svg.paid path#Path_554, 
.step_wrap .steps_ul li.stepper__step_done .step svg.paid path#Path_555, 
.step_wrap .steps_ul li.stepper__step_done .step svg.submit path#Path_564, 
.step_wrap .steps_ul li.stepper__step_done .step svg.submit path#Path_565, 
.step_wrap .steps_ul li.stepper__step_done .step svg.submit path#Path_566, 
.step_wrap .steps_ul li.stepper__step_done .step svg.submit path#Path_567, 
.step_wrap .steps_ul li.stepper__step_done .step svg.submit path#Path_568 {
    fill: #fff;
}

.step_wrap .steps_ul li.stepper__step_done .step svg.submit path#Path_564, 
.step_wrap .steps_ul li.stepper__step_done .step svg.submit path#Path_565, 
.step_wrap .steps_ul li.stepper__step_done .step svg.submit path#Path_566, 
.step_wrap .steps_ul li.stepper__step_done .step svg.submit path#Path_567, 
.step_wrap .steps_ul li.stepper__step_done .step svg.submit path#Path_568 {
    stroke: #fff;
    fill:#FFF;
}
.step_wrap .steps_ul li.stepper__step_done .step .submit path {
    stroke: #fff;
    fill:#FFF;
}
.step_wrap .content_text {
    margin: 40px 0 0;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: 0;
    color: #000;
    font-family: 'Noto Sans JP', sans-serif;
    text-align: center;
}
.content_text .payment_color {
    color: #1a62f5;
    cursor: pointer;
}
/* status Section over */
/* submit section */
.file_wra, 
.file_wra li {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.file_wra {
    list-style: none;
    padding: 0;
    margin-bottom: 0;
}
.file_wra li {
    padding: 15px 20px;
    background: #fbfbfb;
    margin-bottom: 30px;
    overflow: hidden;
    font-family: 'Noto Sans JP', sans-serif;
}
.file_wra li:last-child {
    margin-bottom: 0;
}
.file_wra li .date_text {
    width: 100%;
    font-size: 14px;
    font-weight: 400;
    text-align: left;
    letter-spacing: 0;
    line-height: 16px;
    color: #b2b2b2;
    margin: 0 0 15px;
}
.file_wra li .file_titl {
    margin-bottom: 5px;
    font-weight: 600;
    font-size: 18px;
}
.file_wra li .single_file {
    padding: 20px 0;
    border-bottom: 1px solid #f2f2f2;
}
.file_wra li .single_file:last-child {
    padding-bottom: 0;
    border-bottom: 0;
}
.file_wra li .wrp_fil {
    width: 100%;
    display: flex;
    flex-direction: column;
}
.file_wra li .wrp_fil .fil_lis {
    display: flex;
    width: 100%;
    align-items: center;
    position: relative;
    justify-content: space-between;
}
.file_wra li .wrp_fil .fil_lis p {
    width: 100%;
    margin: 0;
    display: flex;
    padding: 5px 0;
    align-items: center;
}
.file_wra li .wrp_fil .fil_lis p svg {
    margin-right: 15px;
}
.file_wra li .wrp_fil .fil_lis p .file_tex {
    font-size: 14px;
    font-weight: 500;
    letter-spacing: .28px;
    line-height: 16px;
    color: #000;
    margin: 0;
    cursor: default;
}
.file_wra li .wrp_fil .fil_lis .grup_btn {
    position: absolute;
    z-index: 1;
    right: -105px;
    transition: right .3s ease;
}
.file_wra li .single_file .wrp_fil .fil_lis .grup_btn {
    display: flex;
    align-items: center;
}
.file_wra li .wrp_fil .fil_lis .grup_btn .view_filebtn {
    background: #f2f2f2;
    width: 36px;
    display: flex;
    height: 36px;
    align-items: center;
    justify-content: center;
    margin-right: 10px;
}
.btn_downlo {
    background: transparent;
    border: 0;
    padding: 0;
}
/* .file_wra li .wrp_fil .fil_lis .grup_btn .btn_downlo img{
    display: none;
} */
.file_wra li .wrp_fil .fil_lis .grup_btn .btn_downlo .a {
    fill: #f2f2f2;
}
.file_wra li .single_file:hover .wrp_fil .fil_lis .grup_btn {
    right: 0;
}
/* submit section over */
/* modal css */

.modal_closeJob {
    max-width: 600px;
}

.modal_hire {
    max-width: 900px;
}

.hire_modal_body {
    padding: 30px;
}

/* modal css over */

/* pagination */

/* .pagination_wrap {
    /* padding-top: 1rem; *
    margin-top: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 30px 0 10px;
    border-top: 1px solid #ededed;
}

.pagination_wrap>ul {
    padding: 0;
    margin: 0;
}

.pagination_wrap li {
    display: inline-block;
    margin: 0 2px;
}

.pagination_wrap li.previous {
    margin: 0 15px 0 0px;
}

.pagination_wrap li.next {
    margin: 0 0px 0 15px;
}

.pagination_wrap li>a {
    padding: 2px 6px;
    margin: 1px;
    width: 32px;
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    min-width: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #0F1626;
}

.pagination_wrap li>a:hover {
    background: #f5f5f5;
}

.pagination_wrap li>a:focus {
    outline: none;
}

.pagination_wrap li.active>a {
    background: #0F1626;
    border-color: #0F1626;
    color: #FFF;
}

.pagination_wrap li.active>a:hover {
    background: #0F1626;
    border-color: #0F1626;
    color: #FFF;
} */

/* pagination over */

/* pay section */

.pay_wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
}

.pay_wrap .wrap_pay {
    width: 100%;
    display: flex;
    justify-content: flex-start;
}

.pay_wrap .wrap_pay .sub_pay {
    display: inline-block;
    background: #fbfbfb;
    border-radius: 5px;
    padding: 20px 30px;
    position: relative;
    font-family: 'Noto Sans JP';
}
.pay_wrap .wrap_pay .sub_pay .right_icon {
    width: 18px;
    height: 18px;
    border: 0;
    border-radius: 50%;
    display: inline-block;
    background: #3bb54a;
    position: absolute;
    right: 5px;
    top: 5px;
}
.pay_wrap .wrap_pay .sub_pay .right_icon svg {
    top: -5px;
    position: relative;
    left: 1px;
}

.pay_wrap .wrap_pay .sub_pay .right_icon svg .right_arr {
    fill: #fff;
}
.pay_wrap .wrap_pay .sub_pay .btn_refund {
    padding: 0;
    border: 0;
    background: transparent;
    font-size: 16px;
    font-weight: 500;
    line-height: 19px;
    letter-spacing: 0;
    color: #74a2ff;
    position: absolute;
    display: inline-block;
    right: 7px;
    bottom: 7px;
    font-family: 'Noto Sans JP', sans-serif;
}
.pay_wrap .wrap_pay .sub_pay p {
    font-size: 24px;
    font-weight: 700;
    color: #000;
    line-height: 34px;
    letter-spacing: .48px;
    margin-bottom: 0;
}

.pay_wrap .wrap_pay .sub_pay p .type_price {
    display: block;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 34px;
    letter-spacing: .36px;
    color: #b2b2b2;
}

.pay_wrap .wrap_pay .sub_pay p small {
    font-size: 18px;
    margin-right: 5px;
    color: #000;
    font-weight: 700;
}

.pay_wrap .wrap_pay .sub_pay p .price_type {
    display: block;
    text-align: center;
    font-size: 14px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: .36px;
    color: #b2b2b2;
}

.pay_wrap .wrap_pay .sub_pay.max_wdth {
    width: 100%;
    max-width: 420px;
}

.pay_wrap .wrap_pay.flex_end {
    justify-content: flex-end;
}

.pay_wrap .wrap_pay.flex_end .sub_pay {
    width: 100%;
    max-width: 200px;
    text-align: center;
}

.pay_wrap .wrap_pay .sub_pay.mar_50 {
    margin-right: 50px;
}

.info_tex {
    margin-bottom: 100px;
}
.info_tex p{
    font-family: 'Noto Sans JP', sans-serif;
    text-align: left;
    font-size: 18px;
    font-weight: 500;
    line-height: 32px;
    letter-spacing: .54px;
    color: #000;
}

.desc_text {
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: .4px;
    text-align: left;
    margin: 50px 0 60px;
    width: 100%;
    font-family: 'Noto Sans JP', sans-serif;
    display: inline-block;
    position: relative;
    color: #000;
}

.desc_text .line_2 {
    margin-top: 20px;
}

.ul_pay {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-bottom: 0;
    padding-left: 0;
}

.ul_pay li {
    padding-left: 42px;
    list-style: none;
    margin-bottom: 40px;
    font-size: 18px;
    font-weight: 500;
    line-height: 30px;
    letter-spacing: .4px;
    color: #000;
    width: 100%;
    display: block;
    position: relative;
}

.ul_pay li .icon_right {
    content: "";
    position: absolute;
    left: 0;
    top: 4px;
    width: 22px;
    height: 22px;
    border-radius: 20px;
    border: 2px solid #1a62f5;
    display: inline-block;
}

.ul_pay li .icon_right:after {
    content: "";
    position: absolute;
    left: 3px;
    top: 50%;
    width: 13px;
    height: 9px;
    background: url('../images/arrow-right.svg') no-repeat 50%;
    background-size: 12px;
    transform: translateY(-50%);
}

.ul_pay li a {
    color: #1a62f5;
}

.wrap_bottom_pay {
    width: 100%;
    min-height: 235px;
    display: flex;
    align-items: flex-end;
    justify-content: space-between;
    font-family: 'Noto Sans JP', sans-serif;
}

.wrap_bottom_pay p {
    margin-bottom: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 30px;
    letter-spacing: .31px;
    text-align: left;
    color: #646464;
}

.wrap_bottom_pay p a {
    color: #1a62f5;
}

/* pay section over */

/* modal deposit */

.profile_blo {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 25px;
    grid-column-gap: 10px;
    -webkit-column-gap: 10px;
    column-gap: 10px;
}

.pro_wra {
    display: flex;
    align-items: center;
    justify-content: center;
}

.profile_img {
    width: 60px;
    height: 60px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    overflow: hidden;
}

.profile_img img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.pro_wra .review_title {
    font-size: 22px;
    font-weight: 600;
    padding-left: 15px;
    max-width: calc(100% - 60px);
}

.m_payment {
    width: 100%;
    display: inline-block;
    font-family: 'Noto Sans JP', sans-serif;
}

.m_payment h5 {
    font-size: 16px;
    letter-spacing: 0;
    color: #898989;
    font-weight: 500;
    margin-bottom: 20px;
}

.m_payment .amt {
    display: flex;
    background: #f6f6f6;
    width: 150px;
    height: 70px;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    float: left;
    margin-right: 30px;
    border-radius: 7px;
}

.m_payment .amt b small {
    font-weight: 700;
}

.m_payment .amt span {
    font-size: 12px;
    color: #8b8b8b;
}

.deposit_div {
    margin: 50px 0;
    padding-left: 0;
    list-style: none;
}

.deposit_div li {
    letter-spacing: .35px;
    font-size: 16px;
    font-weight: 500;
    margin-bottom: 12px;
    position: relative;
    padding-left: 40px;
    font-family: 'Noto Sans JP', sans-serif;
}

.deposit_div li:before {
    content: "";
    position: absolute;
    width: 22px;
    height: 22px;
    left: 0;
    top: 1px;
    border: 2px solid #1a62f5;
    border-radius: 50%;
}

.deposit_div li:after {
    content: "";
    position: absolute;
    left: 4px;
    top: 12px;
    width: 13px;
    height: 9px;
    background: url('../images/arrow-right.svg') no-repeat 50%;
    background-size: 12px;
    transform: translateY(-50%);
}

.deposit_div li a {
    text-decoration: underline;
    color: #007bff;
}

.gru_btn {
    display: flex;
    justify-content: space-between;
    align-items: flex-end;
}

.gru_btn p {
    margin: 0;
    font-size: 12px;
    font-family: 'Noto Sans JP', sans-serif;
}

.gru_btn p a {
    color: #007bff;
}

/* modal deposit over */

/* details page design */

.bg_overlay {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background: rgb(0 0 0 / 55%);
    z-index: 9995;
    display: none;
}
.bg_overlay.show{
    display: block;
}
.details_wrap{
    width: 100%;
    max-width: calc(100% - 30vw);
    position: fixed;
    right: -100%;
    top: 0;
    bottom: 0;
    z-index: 9996;
    overflow: hidden;
    overflow-y: auto;
    background: #EFEFEF;
    transition: right 0.3s linear;
    /* display: none; */
}
.details_wrap.show{
    right: 0;
    /* right: -100%; */
    /* display: block; */
}
.header_wrap{
    width: 100%;
    display: inline-block;
    position: relative;
    background: #FFF;
    border-bottom: 1px solid rgb(0 0 0 / 10%);
    padding: 15px 25px;
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
}
.header_wrap .back_wrap{
    background: transparent;
    border: 0;
    padding: 0;
    font-size: 28px;
    color: #0F1626;
}
.details_wrap_body{
    width: 100%;
    display: inline-block;
    position: relative;
    padding: 25px 30px;
}
.wrap_detail{
    width: 100%;
    max-width: calc(100% - 10vw);
    padding-right: var(--bs-gutter-x,.75rem);
    padding-left: var(--bs-gutter-x,.75rem);
}
.button_wrap{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 35px 0;
    border-bottom: 1px solid #EFEFEF;
}
.wrap_detail .title_wrap_details{
    padding: 25px 15px;
    border-bottom: 1px solid #EFEFEF;
}
.wrap_detail .title_wrap_details h3{
    margin-bottom: 0;
    font-size: 22px;
    font-weight: 600;
    line-height: 28px;
}
.wrap_detail .description_wraps{
    padding: 25px 15px;
    border-bottom: 1px solid #EFEFEF;
}
.wrap_detail .description_wraps p{
    font-size: 15px;
    line-height: 22px;
    color: #25313c;
}
.price_wrap,
.type_project{
    padding: 25px 15px;
    border-bottom: 1px solid #EFEFEF;
}
.price_wrap p{
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: #0F1626;
}
.price_wrap p span{
    display: block;
    opacity: 0.5;
    font-size: 14px;
}
.type_project p{
    margin-bottom: 0;
    font-size: 18px;
    font-weight: 600;
    color: #0F1626;
}
.type_project p span{
    font-weight: 400;
}
.skills_wrap_detail{
    padding: 25px 15px;
}
.skills_wrap_detail h5{
    font-weight: 600;
    font-size: 18px;
    margin-bottom: 15px;
}
.skills_wrap_detail p{
    font-size: 16px;
    font-weight: 500;
    color: #0F1626;
    margin-bottom: 10px;
}
.skills_wrap_detail .budges_skills{
    list-style: none;
    margin-bottom: 0;
    padding-left: 0;
}
.skills_wrap_detail .budges_skills li{
    padding: 5px 10px;
    font-size: 13px;
    font-weight: 500;
    background: #e2e2e2;
    display: inline-block;
    margin: 0 10px 10px 0px;
    border-radius: 30px;
}
.border_end{
    border-right: 1px solid #EFEFEF;
}
/* details page design over*/

@media screen and (max-width:1024px) {
    .step_wrap .steps_ul li.stepper__step:after {
        width: 9.6rem;
    }
}

@media screen and (max-width:991px) {
    .freelancer_list_wrap li {
        padding: 20px;
    }
    .right_detail {
        padding: 0 0 0 20px;
    }
    .pay_wrap .wrap_pay .sub_pay.max_wdth {
        max-width: 300px;
    }
    .pay_wrap .wrap_pay .sub_pay.mar_50 {
        margin-right: 30px;
    }
    .pay_wrap .wrap_pay .sub_pay p {
        font-size: 18px;
    }
    .pay_wrap .wrap_pay .sub_pay p .type_price {
        font-size: 15px;
    }
    .ul_pay li, .desc_text {
        font-size: 16px;
    }
    .step_wrap .steps_ul li.stepper__step:after {
        left: 80%;
        transform: translate(58%,-50%);
        width: 3.5rem;
    }
    .file_wra li .wrp_fil .fil_lis p .file_tex {
        font-size: 13px;
        margin-left: 15px;
        word-break: break-all;
        width: calc(100% - 70px);
    }
    .file_wra li .wrp_fil .fil_lis .grup_btn {
        position: relative;
        right: 0;
        margin-top: 0px;
        margin-left: 15px;
    }
}

@media screen and (max-width:767px) {
    .btn {
        margin-left: 0;
    }
    .title_listing {
        margin-bottom: 20px;
        font-size: 20px;
    }
    .user_prof {
        width: 70px;
        height: 70px;
    }
    .right_detail {
        padding-right: 0;
    }
    .caption {
        font-size: 22px;
        line-height: 32px;
    }
    .bottom_freela {
        flex-direction: column;
        align-items: center;
        justify-content: unset;
    }
    .btns {
        margin-top: 40px;
        justify-content: space-between;
        width: 100%;
    }
    .pay_wrap .wrap_pay .sub_pay {
        padding: 15px;
    }
    .pay_wrap .wrap_pay .sub_pay.max_wdth {
        max-width: 100%;
        padding: 15px;
        margin-bottom: 20px;
    }
    .pay_wrap .wrap_pay .sub_pay.mar_50 {
        margin-right: 25px;
    }
    .pay_wrap .wrap_pay .sub_pay.mar_50 {
        margin-right: 30px;
    }
    .pay_wrap .wrap_pay .sub_pay p {
        font-size: 18px;
    }
    .pay_wrap .wrap_pay .sub_pay p .type_price {
        font-size: 14px;
    }
    .desc_text {
        font-size: 16px;
    }
    .ul_pay li {
        margin-bottom: 15px;
    }
    .step_wrap .steps_ul li.stepper__step.stepper__step_current:after, 
    .step_wrap .steps_ul li.stepper__step.stepper__step_done:after, 
    .step_wrap .steps_ul li.stepper__step:after {
        display: none;
    }
    /* .file_wra li .wrp_fil .fil_lis {
        flex-direction: column;
        align-items: unset;
    } */
    .file_wra li .wrp_fil .fil_lis p .file_tex {
        font-size: 13px;
        margin-left: 15px;
        word-break: break-all;
        width: calc(100% - 70px);
    }
    .file_wra li .wrp_fil .fil_lis .grup_btn {
        position: relative;
        right: 0;
        margin-top: 0px;
        margin-left: 15px;
    }
}

@media screen and (max-width:576px) {
    .btn {
        margin-left: 0;
    }
    .title_listing {
        margin-bottom: 15px;
        font-size: 18px;
    }
    .freelancer_list_wrap li {
        flex-direction: column;
        padding: 20px 15px;
    }
    .right_detail {
        width: 100%;
        padding: 30px 0 0;
    }
    .user_review, .profile_wrap {
        flex-direction: column;
        justify-content: unset;
        align-items: flex-start;
        /* width: 100%; */
    }
    .caption {
        font-size: 19px;
        line-height: 30px;
    }
    .price {
        margin-top: 15px;
    }
    .pay_wrap {
        flex-direction: column;
    }
    .pay_wrap .wrap_pay .sub_pay.mar_50 {
        margin-right: 15px;
    }
    .pay_wrap .wrap_pay .sub_pay p {
        font-size: 18px;
        margin-bottom: 15px;
    }
    .ul_pay li {
        font-size: 15px;
    }
    .profile_blo {
        flex-direction: column;
        align-items: flex-start;
    }
    .pro_wra .review_title {
        font-size: 18px !important;
    }
    .m_payment .amt {
        margin-right: 8px;
        width: 125px;
    }
    .deposit_div li {
        letter-spacing: .1px;
        font-size: 14px;
    }
    .gru_btn {
        align-items: center;
        flex-direction: column-reverse;
    }
    .gru_btn p {
        line-height: 16px;
        margin-top: 12px;
    }
    .freelancer_wr {
        flex-direction: column;
        margin-bottom: 25px;
    }
    .freelancer_wr .img_wr {
        margin-bottom: 15px;
    }
    .freelancer_wr .user_name {
        padding-left: 0;
        text-align: center;
        margin-bottom: 15px;
    }
    .step_wrap {
        padding: 50px 25px;
    }
    .step_wrap .steps_ul {
        flex-direction: column;
    }
    .step_wrap .steps_ul li {
        margin-bottom: 90px;
    }
    .step_wrap .steps_ul li:last-child {
        margin-bottom: 0;
    }
    .step_wrap .steps_ul li.stepper__step.stepper__step_current:after, 
    .step_wrap .steps_ul li.stepper__step.stepper__step_done:after, 
    .step_wrap .steps_ul li.stepper__step:after {
        display: block;
        transform: rotate(90deg);
        width: 50px;
        left: 18%;
        top: 140px;
    }
    .step_wrap .steps_ul li.stepper__step:last-child::after{
        display: none;
    }
    .file_wra li .wrp_fil .fil_lis {
        flex-direction: column;
        align-items: unset;
    }
    .file_wra li .wrp_fil .fil_lis p .file_tex {
        font-size: 13px;
        margin-left: 15px;
        word-break: break-all;
        width: calc(100% - 70px);
    }
    .file_wra li .wrp_fil .fil_lis .grup_btn {
        position: relative;
        right: 0;
        margin-top: 15px;
    }
}

@media screen and (max-width:320px) {
    .hire_modal_body {
        padding: 15px;
    }
    .pricing_block label {
        font-size: 16px;
    }
}