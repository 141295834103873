.freelancer_section {
    width: 100%;
    margin-top: 56px;
    padding: 50px 0;
    font-family: 'Noto Sans JP', sans-serif;
}

.filter_wrap {
    width: 100%;
    /* box-shadow: rgba(0, 0, 0, 0.1) 0px 0px 30px; */
    background: rgb(255, 255, 255);
    border-radius: 8px;
    padding: 20px;
    transition: 0.4s linear;
}

.main_filter_div {
    display: flex;
    align-items: center;
    justify-content: space-between;
    /* margin-bottom: 15px; */
    font-family: 'Noto Sans JP', sans-serif;
}

.collapse_filter {
    width: 100%;
    display: inline-block;
    position: relative;
    margin-top: 15px;
    transition: display 0.3s linear;
}

.filter_icon {
    cursor: pointer;
}

.main_filter_div h3 {
    font-size: 22px;
    font-weight: 700;
}

.filter_radio button {
    width: calc(50% - 10px);
    font-size: 14px;
    font-weight: 500;
    letter-spacing: 0.14px;
    color: rgb(0, 0, 0);
    justify-content: center;
    line-height: 20px;
    margin-bottom: 0px;
    padding: 4px;
    border-width: 1px;
    border-style: solid;
    border-color: rgb(0, 0, 0);
    border-image: initial;
    border-radius: 3px;
    background: transparent;
}

.filter_radio button.active {
    color: rgb(255, 255, 255);
    background: #0F1626;
    border-color: #0F1626;
}

.main_area h5 {
    font-size: 14px;
    font-weight: 600;
    margin-bottom: 15px;
}

.division {
    margin-bottom: 15px;
    padding-bottom: 15px;
    border-bottom: 1px solid rgb(206, 206, 206);
}

.posting {
    display: flex;
    justify-content: space-between;
}

.radio_group {
    flex-wrap: wrap;
    padding-bottom: 0;
    border-bottom: 0;
    margin-bottom: 0;
    padding-left: 0;
    position: relative;
    display: flex;
}

.radio_group li {
    width: calc(50% - 5px);
    margin-bottom: 10px;
    display: inline-block;
    list-style: none;
}

.radio_group li label {
    width: 100%;
    color: #a7a7a7;
    font-size: 12px;
    border: 1px solid #a7a7a7;
    font-weight: 400;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    display: inline-block;
    text-align: center;
    padding: 4px;
    justify-content: center;
    line-height: 20px;
    border-radius: 3px;
    margin-bottom: 0;
    cursor: pointer;
}

.radio_group li label.active {
    background: #0F1626;
    color: #fff;
    border-color: #0F1626;
}

.result_wrap {
    padding: 30px 20px;
    margin-left: 20px;
    width: 100%;
    position: relative;
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
    background: #fff;
    border-radius: 8px;
    font-family: 'Noto Sans JP', sans-serif;
}

.tabs_ul {
    padding-left: 0;
    list-style: none;
    display: flex;
    margin-bottom: 30px;
    border-bottom: 1px solid #e8e8e8;
    position: relative;
}

.tabs_ul li {
    padding: 0 0 8px;
    text-align: center;
    position: relative;
    background-color: #fff;
}

.tabs_ul li button {
    font-size: 16px;
    font-weight: 400;
    color: #828081;
    padding: 0 10px;
    background-color: transparent;
    border: 0;
}

.tabs_ul li button.active {
    color: #0F1626;
    font-weight: 600;
}

.tabs_ul li button.active:after {
    content: "";
    position: absolute;
    bottom: 0;
    left: 0;
    background: #727988;
    width: 100%;
    height: 2px;
}

.listing_section .form_search {
    position: relative;
    margin-bottom: 30px;
    padding-bottom: 30px;
    border-bottom: 1px solid #e8e8e8;
}

.listing_section .form_search input {
    border: 1px solid #a7a7a7;
    border-radius: 3px;
    width: 100%;
    font-size: 14px;
    padding: 8px 20px;
    line-height: 22px;
}

.listing_section .form_search input:focus {
    outline: 0;
    box-shadow: unset;
}

.listing_section .form_search button {
    background: #fcaf17;
    /* background: #0F1626; */
    border-radius: 0 3px 3px 0;
    border: none;
    position: absolute;
    right: 0;
    top: 0;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.w_search {
    fill: #fff;
    stroke: #fff;
    stroke-width: .4px;
}

/* freelancer block */

.freelancers_ul {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 0;
    padding: 0;
    list-style: none;
}

.freelancers_ul li {
    list-style: none;
}

.review_div.freeList {
    display: flex;
    width: 100%;
    border-bottom: 1px solid #ededed !important;
    margin-bottom: 30px;
    position: relative;
    padding: 0 0 30px;
    box-shadow: unset;
    border-radius: 0;
}

.freelancers_ul li:last-child .review_div {
    margin-bottom: 0;
    border-bottom: 0 !important;
}

.review_div .user_prof {
    width: 80px;
    height: 80px;
    border-radius: 50%;
    text-align: center;
    display: inline-block;
    overflow: hidden;
}

.review_div .user_prof img {
    height: 100%;
    object-fit: cover;
    width: 100%;
}

.right_details {
    width: calc(100% - 80px);
    padding-left: 23px;
}

.review_div .caption_wrap {
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 10px;
}

.review_div .caption_wrap .review_title {
    font-size: 20px;
    font-weight: 600;
    margin-bottom: 0;
    line-height: 20px;
    letter-spacing: .2px;
    color: #1a62f5;
}

.review_div .caption_wrap .btns, .review_div .caption_wrap .save_btn {
    display: flex;
    align-items: center;
    justify-content: center;
}

.review_div .caption_wrap .save_btn {
    width: 30px;
    height: 30px;
    padding: 0;
    background-color: transparent;
    border-radius: 50%;
    border: 1px solid #000;
    outline: none;
}
.review_div .caption_wrap .save_btn.active{
    background-color: #000;
    color: #FFF;
    
}
.review_div .caption_wrap .save_btn.active svg path{
    fill: #FFF;
    stroke-width: 3;
}

.detail {
    width: 100%;
    display: inline-block;
}

.review_div .price {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 15px;
    line-height: 20px;
    color: #0F1626;
}

.review_div .price span {
    font-size: 16px;
    font-weight: 500;
    color: #898989;
}

.review_div .summary {
    font-size: 14px;
    letter-spacing: .12px;
    color: #898989;
    margin-bottom: 18px;
    word-break: break-all;
}

.review_div .user_r_detail, .review_div .user_review {
    display: flex;
    justify-content: flex-start;
    align-items: center;
}

.review_div .user_review {
    width: 100%;
    margin-bottom: 10px;
}

.rating_find {
    display: flex;
    width: auto;
    align-items: center;
    margin-right: 18px;
}

.rating_find .rating_badge {
    display: inline-block;
    width: auto;
    background: #ffc12d;
    border-radius: 3px;
    color: #fff;
    font-size: 12px;
    padding: 2px 5px;
    font-weight: 700;
    margin: 0 5px 0 0 !important;
}

.rating_find span {
    font-size: 14px;
    font-weight: 500;
    color: #000;
    margin-left: 5px;
}

.review_div .flag {
    width: 28px;
    height: 17px;
    display: inline-block;
    margin: 0 10px;
    border-radius: 3px;
}

.review_div .user_r_detail .p {
    margin: 0;
    font-size: 14px;
    font-weight: 500;
    color: #000;
    overflow: unset;
}

.s_listing {
    padding: 0;
    width: 100%;
}

.s_listing li {
    display: inline-block;
    font-size: 12px;
    color: #000;
    font-weight: 500;
    position: relative;
    margin-right: 15px;
    padding-left: 12px;
}

.s_listing li:before {
    content: "";
    position: absolute;
    left: 0;
    top: 6px;
    width: 6px;
    height: 6px;
    background: #1a62f5;
    border-radius: 50%;
}

/* freelancer block over*/

/* pagination */

.pagination_wrap {
    /* padding-top: 1rem; */
    margin-top: 15px;
    text-align: center;
    display: flex;
    justify-content: center;
    padding: 30px 0 10px;
    border-top: 1px solid #ededed;
}

.pagination_wrap>ul {
    padding: 0;
    margin: 0;
}

.pagination_wrap li {
    display: inline-block;
    margin: 0 2px;
}

.pagination_wrap li.previous {
    margin: 0 15px 0 0px;
}

.pagination_wrap li.next {
    margin: 0 0px 0 15px;
}

.pagination_wrap li>a {
    padding: 2px 6px;
    margin: 1px;
    width: 32px;
    display: inline-block;
    cursor: pointer;
    background-color: #fff;
    border: 1px solid #f5f5f5;
    border-radius: 4px;
    min-width: 1rem;
    font-family: 'Roboto', sans-serif;
    font-size: 15px;
    color: #0F1626;
}

.pagination_wrap li>a:hover {
    background: #f5f5f5;
}

.pagination_wrap li>a:focus {
    outline: none;
}

.pagination_wrap li.active>a {
    background: #0F1626;
    border-color: #0F1626;
    color: #FFF;
}

.pagination_wrap li.active>a:hover {
    background: #0F1626;
    border-color: #0F1626;
    color: #FFF;
}

/* pagination over */

@media screen and (max-width:1145px) {
    .result_wrap {
        margin-left: 0px;
        /* width: calc(72% - 7px); */
    }
    .rating_badge {
        font-size: 16px;
    }
    .filter_radio button{
        font-size: 0.8em;
    }
}

@media screen and (max-width:991px) {
    .listing_section .review_div {
        flex-direction: column;
    }
    .result_wrap {
        margin-left: 0px;
        /* width: calc(64% - 10px) */
    }
    .listing_section .right_details {
        width: 100%;
        padding: 20px 0 0;
    }
    .filter_radio button{
        font-size: 13px;
    }
}

@media screen and (max-width:767px) {
    .collapse_filter {
        display: none;
    }
    .collapse_filter.active {
        display: inline-block;
    }
    .result_wrap {
        margin: 25px 0 20px;
        width: 100%;
    }
    
}

@media screen and (max-width:576px) {
    .result_wrap {
        margin-top: 25px;
        padding: 20px 15px;
    }
    .listing_section .form_search input {
        padding: 3px 10px;
    }
    .review_div .caption_wrap {
        flex-direction: column;
        align-items: flex-start;
    }
    .review_div .caption_wrap .review_title {
        margin-bottom: 15px;
    }
    .review_div .user_review {
        width: 100%;
        margin-bottom: 10px;
        flex-direction: column;
        align-items: flex-start;
    }
    .review_div .user_r_detail {
        margin-top: 8px;
    }
    .review_div .flag {
        margin: 0 10px 0 0;
        width: 23px;
        height: 14px;
    }
}