.info_section {
    width: 100%;
    padding: 50px 0;
    position: relative;
}

.tabs_wrap {
    width: 100%;
    display: block;
    padding: 25px;
    border-radius: 8px;
    background: #FFF;
    font-family: 'Noto Sans JP', sans-serif;
    box-shadow: 0 3px 8px rgba(0 0 0 / 5%), 0 8px 28px rgba(0 0 0 / 15%);
}

.tab_header {
    width: 100%;
    border-bottom: 1px solid rgba(0 0 0 / 15%);
    padding-bottom: 15px;
}

.tab_header .tabs_title {
    font-size: 20px;
    font-weight: 600;
    color: #0F1626;
    margin-bottom: 0;
}

.tab_footer {
    width: 100%;
    border-top: 1px solid rgba(0 0 0 / 15%);
    padding-top: 18px;
    display: flex;
    flex-direction: row-reverse;
    align-items: center;
    justify-content: space-between;
}

.tab_body {
    width: 100%;
    padding: 25px 0;
    display: inline-block;
    position: relative;
}

.body_title {
    font-size: 18px;
    font-weight: 600;
    margin-bottom: 2rem;
    color: #0F1626;
}

.form_group {
    width: 100%;
    display: block;
    position: relative;
    margin-bottom: 30px;
}

.form_group:last-child {
    margin-bottom: 0;
}

.form_group.max_500 {
    max-width: 500px;
}

.form_group label {
    font-size: 15px;
    font-weight: 600;
    color: #0F1626;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 10px;
}

.info_textbox_text {
    font-size: 13px;
    margin-bottom: 0;
    font-weight: 500;
    color: #0F1626;
    margin-top: 5px;
    text-align: right;
    opacity: 0.7;
    font-family: 'Roboto', sans-serif;
}

.bg-start{
    width: 100%;
    padding: 15px;
    background: #f7f7f7;
    text-align: center;
    overflow: hidden;
}
.bg-start img{
    max-height: 250px;
}
/* file_wrap */

.file_wrap {
    width: 100%;
    max-width: 180px;
    height: 180px;
    display: inline-block;
    margin: 15px 0;
    position: relative;
}

.file_wrap input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
}

.file_wrap .file_label {
    width: 100%;
    height: 180px;
    border: 2px dashed rgba(0 0 0 / 15%);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: relative;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 500;
    color: rgba(0 0 0 / 45%);
    cursor: pointer;
}

.file_wrap .file_label i {
    margin-bottom: 15px;
    font-size: 75px;
}

.new-image-wrap{
    display: block;
    width: 100%;
    max-width: 80px;
    position: relative;
    margin: 0 auto 25px;
}
.new-image-wrap .img_block{
    width: 80px;
    height: 80px;
    overflow: hidden;
    border-radius: 50%;
}
.new-image-wrap .img_block img{
    width: 100%;
    height: 100%;
    object-fit: cover;
}
.new-file-wrap {
    position: absolute;
    z-index: 1;
    right: 0;
    bottom: -2px
}   
.new-file-wrap input[type="file"] {
    opacity: 0;
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    display: none;
    cursor: pointer;
}

.new-file-wrap .file-label {
    width: 30px;
    height: 30px;
    border: 0;
    margin: 0;
    font-family: inherit;
    font-weight: 500;
    color: rgba(0 0 0 / 45%);
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    line-height: 1;
    text-align: center;
    background: #FFF;
    border-radius: 50%;
    box-shadow: 0 3px 8px rgba(0 0 0 / 5%), 0 5px 15px rgba(0 0 0 / 15%);

}

.new-file-wrap .file-label .user-icon {
    margin-bottom: 0px;
    font-size: 18px;
}

.image_wap {
    width: 100%;
    max-width: 180px;
    height: 180px;
    text-align: center;
}

.image_wap .image {
    /* width: 100%; */
    width: 140px;
    height: 140px;
    border-radius: 50%;
    overflow: hidden;
    margin: 0 auto;
}

.image_wap .image img {
    width: 100%;
    object-fit: cover;
}

.image_wap .delete_btn {
    background: transparent;
    border: 1px solid #D32F2F;
    color: #D32F2F;
    padding: 5px 15px;
    font-family: 'Noto Sans JP', sans-serif;
    border-radius: 5px;
    margin-top: 15px;
    font-size: 14px;
    cursor: pointer;
}

/* file_wrap over */

/* contact details */

.phone_container {
    width: 100%;
}

.contact_label {
    font-size: 15px;
    font-weight: 500;
    font-family: 'Roboto', sans-serif;
    margin-bottom: 5px;
    display: block;
}

/* contact details over */

/* test section */

.check_box {
    width: 20px;
    height: 20px;
    display: inline-block;
    position: relative;
    border: 1px solid rgba(0 0 0 / 15%);
    border-radius: 50%;
    margin-right: 8px;
    background: transparent;
}

.check_box.active {
    background-color: #072AC8;
}

.check_box::after {
    content: '\2714';
    position: absolute;
    top: 50%;
    left: 50%;
    font-size: 13px;
    line-height: 16px;
    color: #FFF;
    transform: translate(-50%, -50%) scale(0);
    transition: 0.3s all;
}

.check_box.active::after {
    transform: translate(-50%, -50%) scale(0.8);
}

/* test section over */