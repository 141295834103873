.footer-section {
    width: 100%;
    background: #303030;
    /* background: #222842; */
    /* background: #ccedf9; */
    padding: 92px 0 10px;
}

.footer-social {
    padding: 19px 0;
    width: 100%;
    border-top: 1px solid #FFF;
    border-bottom: 1px solid #FFF;
}

.footer-social .footer-social-header {
    color: #FFF;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 500;
    font-size: 0.8rem;
    margin-right: 10px;
}

.footer-social .footer-social-list {
    list-style: none;
    display: flex;
    align-items: center;
    padding-left: 0;
    margin: 0;
}

.footer-social .footer-social-list .footer-social-list-item {
    display: inline-block;
    margin: 0 10px 0 0;
}

.footer-social .footer-social-list .footer-social-list-item .footer-social-list-link {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 30px;
    height: 30px;
    color: #FFF;
    background: transparent;
    text-align: center;
    border-radius: 50%;
    border: 1px solid #FFF;
}

.footer-social .footer-social-list .footer-social-list-item .footer-social-list-link:hover {
    background-color: rgba(255, 255, 255, 0.08);
}

.footer-social .footer-social-list .footer-social-list-item .footer-social-list-link .icon {
    /* --size: 14px; */
    display: inline-block;
    width: 14px;
    height: 14px;
}

.footer-social .footer-social-list .footer-social-list-item .footer-social-list-link .icon svg {
    fill: #FFF;
    -o-object-fit: contain;
    object-fit: contain;
    width: 100%!important;
    height: 100%!important;
    display: block;
}

.bottom-container {
    display: flex;
    padding-bottom: 20px;
    padding-top: 20px;
}

.bottom-container .copy {
    margin-bottom: 0;
    /* margin: 0 0 30px; */
    margin-right: 30px;
    padding-right: 30px;
    border-right: 1px solid #FFF;
    font-size: 12px;
    line-height: 20px;
    color: #FFF;
}

.bottom-container .links {
    margin-bottom: 0;
    padding-left: 0;
    list-style: none;
    /* display: flex; */
    align-items: center;
}

.bottom-container .links .link-item {
    margin-bottom: 0;
    margin-right: 30px;
    display: inline-block;
    padding-left: 5px;
    padding-right: 5px;
}

.bottom-container .links .link-item a {
    font-size: 12px;
    line-height: 20px;
    font-weight: 500;
    color: #FFF;
}

@media screen and (max-width:576px) {
    .footer-section{
        padding: 20px 0 10px;
    }
    .footer-social {
        flex-direction: column;
        align-items: flex-start !important;
        padding: 0;
        border-top: 0;
    }
    .footer-social .footer-social-icons {
        flex-direction: column;
        align-items: flex-start !important;
    }
    .footer-social .footer-social-icons.footer-download {
        padding-bottom: 20px;
    }
    .footer-social .footer-social-icons .footer-social-header {
        padding:20px 0px;
        text-transform: none;
        border-top: none;
    }
    .bottom-container{
        flex-direction: column;
        padding-bottom: 0;
    }
    .bottom-container .copy{
        margin-bottom: 10px;
        border-right: 0;
    }
    .bottom-container .links .link-item {
        margin-bottom: 10px;
        margin-right: 15px;
    }
    .bottom-container .links .link-item:last-child{
        margin-right: 0;
    }
}