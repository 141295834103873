.post_job_wrap {
    width: 100%;
    display: block;
    position: relative;
}

.card_wrap {
    padding: 20px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
}

.card_wrap.p_30 {
    padding: 30px;
}

.content_steps_wrap {
    width: 100%;
    min-height: 600px;
    padding: 30px;
    background: #fff;
    border-radius: 8px;
    box-shadow: 0 0 30px rgb(0 0 0 / 10%);
    margin-top: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 60px;
}

.content_steps_wrap .step_body {
    width: 100%;
    display: inline-block;
    position: relative;
}

.content_steps_wrap .header_step {
    border-bottom: 1px solid rgba(151, 151, 151, 0.314);
    padding-bottom: 20px;
    margin-bottom: 25px;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: relative;
}

.content_steps_wrap .header_step .Header_Step_title {
    font-size: 22px;
    font-weight: 700;
    line-height: 26px;
    color: #000;
    margin: 0;
    font-family: 'Noto Sans JP', sans-serif;
}
.content_steps_wrap .header_step .search_box{
    position: relative;
}
.content_steps_wrap .header_step .search_box .search_input{
    padding-left: 35px;
    font-family: 'Noto Sans JP', sans-serif;
}
.content_steps_wrap .header_step .search_box .input_icons{
    position: absolute;
    top: -1px;
    left: 8px;
    font-size: 24px;
    color: rgba(0, 0, 0, 0.43);
}

.content_steps_wrap .wrap_steps {
    width: 100%;
    display: inline-block;
    position: relative;
}

.content_steps_wrap .wrap_steps .tip_wrap {
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 25px 0;
}

.content_steps_wrap .wrap_steps .tip_wrap .tip_title {
    width: 100%;
    display: inline-block;
    color: #000;
    font-weight: 700;
    font-size: 16px;
    margin-bottom: 10px;
    font-family: 'Noto Sans JP', sans-serif;
}

.content_steps_wrap .wrap_steps .tip_wrap .tip_ul {
    width: 100%;
    padding-left: 22px;
    display: inline-block;
    position: relative;
}

.content_steps_wrap .wrap_steps .tip_wrap .tip_ul li {
    width: 100%;
    color: #8a8f9a;
    /* color: #585858; */
    letter-spacing: .42px;
    font-weight: 600;
    font-size: 14px;
    line-height: 22px;
    list-style: disc outside none;
    list-style: initial;
    font-family: 'Noto Sans JP', sans-serif;
    margin-bottom: 5px;
}

.content_steps_wrap .wrap_steps .tip_wrap .tip_ul li:last-child {
    margin-bottom: 0;
}

.content_steps_wrap .step_footer {
    border-top: 1px solid rgba(151, 151, 151, 0.314);
    margin-top: 20px;
    padding: 30px 0 0;
    width: 100%;
    display: flex;
    justify-content: space-between;
    flex-direction: row-reverse;
}

.content_steps_wrap .category_list {
    width: 100%;
    max-height: 300px;
    display: inline-block;
    position: relative;
    margin: 0 -5px;
    padding-left: 0;
    list-style: none;
    overflow: hidden;
    transition: max-height 0.3s ease-in-out;
}

.content_steps_wrap .category_list.more{
    max-height: 450px;
    overflow: hidden;
    overflow-y: auto;
}

.content_steps_wrap .category_list li {
    width: 33.33%;
    float: left;
    border: none;
    padding: 0 15px;
    margin-bottom: 30px;
    font-size: 15px;
    font-family: 'Noto Sans JP', sans-serif;
    color: rgba(0, 0, 0, 0.43);
}

.content_steps_wrap .category_list li input[type="checkbox"]{
    display: none;
}

.content_steps_wrap .category_list li .liDiv_list {
    width: 100%;
    float: left;
    flex-direction: column;
    color: rgba(0, 0, 0, 0.43);
    font-size: 15px;
    padding: 10px 15px;
    margin: 0;
    align-items: flex-start;
    border: 1px solid rgba(0, 0, 0, 0.43);
    font-weight: 500;
    border-radius: 4px;
    /* min-height: 70px; */
    position: relative;
    transition: all .3s;
    cursor: pointer;
    font-family: 'Noto Sans JP', sans-serif;
}

.content_steps_wrap .category_list li .liDiv_list.active,
.content_steps_wrap .category_list li input[type="checkbox"]:checked + .liDiv_list {
    border-color: var(--richBlack);
    background: var(--richBlack);
    /* border-color: #1a62f5;
    background: #1a62f5; */
    color: #FFF;
    box-shadow: 0 3px 10px rgb(15 22 38 / 30%);
    /* box-shadow: 0 3px 10px rgb(26 96 255 / 30%); */
}

.content_steps_wrap .category_list li .liDiv_list.active::after,
.content_steps_wrap .category_list li input[type="checkbox"]:checked + .liDiv_list::after {
    content: "";
    position: absolute;
    width: 15px;
    height: 15px;
    background: url('../images/check-mark-button.svg');
    right: 10px;
    top: 50%;
    transform: translate(0, -50%);
    display: inline-block;
    background-size: 15px;
    -webkit-filter: invert(1);
    filter: invert(1);
}
.budget_wrap{
    display: inline-block;
    position: relative;
    width: 100%;
    margin-bottom: 25px;
}
.budget_wrap input{
    padding-left: 35px;
    
}
.budget_wrap input[type="number"]::-webkit-outer-spin-button,
.budget_wrap input[type=number]::-webkit-inner-spin-button{
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    -ms-appearance: none;
}
.budget_wrap .input_icons{
    position: absolute;
    top: 6px;
    left: 11px;
    font-size: 18px;
    color: rgba(0, 0, 0, 0.43);
}

.deadline_wrap{
    width: 100%;
    display: inline-block;
    position: relative;
    margin: 35px 0 0px;
}
.deadline_wrap .date_Describ{
    font-size: 16px;
    font-family: 'Noto Sans JP', sans-serif;
    font-weight: 400;
    color: rgba(0, 0, 0, 0.73);
    margin:15px 0;
}
.deadline_wrap .diff_DateWrap{
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
    min-height: 250px;
}
.deadline_wrap .diff_DateWrap p{
    font-size: 25px;
    color: #ff4949;
    font-weight: 600;
    margin-bottom: 0;
    font-family: 'Noto Sans JP', sans-serif;
}
.deadline_wrap .diff_DateWrap p span{
    color: #000;
}

.description_wrap{
    width: 100%;
    display: inline-block;
    position: relative;
}
.label_postJOb{
    font-size: 16px;
    font-weight: 700;
    line-height: 26px;
    margin: 0 0 10px;
    font-family: 'Noto Sans JP', sans-serif;
    color: #000;
}
.description_wrap textarea{
    width: 100%;
    resize: none;
    border: 1px solid #979797;
    border-radius: 4px;
    padding: 16px;
    height: 170px;
    font-size: 14px;
    font-weight: 400;
    letter-spacing: .67px;
    opacity: .75;
    color: #000;
    font-family: 'Noto Sans JP', sans-serif;
}
.description_wrap .length_description{
    font-size: 12px;
    color: #1a62f5;
    font-weight: 400;
    width: 100%;
    text-align: right;
    font-family: 'Noto Sans JP', sans-serif;
    margin-top: 3px;
}
.file_wrap2{
    position: relative;
    margin-bottom: 0;
}
.file_wrap2 input[type="file"]{
    padding: 55px;
    font-size: 0;
    margin: 0;
    width: 100%;
    max-width: 100%;
    border: none;
    cursor: pointer;
}
.file_label{
    width: 100%;
    margin: 0;
    position: absolute;
    pointer-events: none;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background: #fff;
    flex-direction: column;
    border: 2px dashed #979797;
    border-radius: 4px;
    color: #000;
    font-weight: 700;
    font-size: 16px;
    font-family: 'Noto Sans JP', sans-serif;
    cursor: pointer;
}
.file_label p{
    font-size: 9px;
    font-weight: 500;
    margin: 10px 0 5px;
}
.file_label p b{
    color: #1a62f5;
    font-size: 10px;
    letter-spacing: .71px;
    padding: 0 5px;
    font-weight: 600;
}
.file_label span{
    color: #cecece;
    font-size: 10px;
}
.ul_files{
    width: 100%;
    display: inline-block;
    padding-left: 0;
    margin-bottom: 0;
}
.ul_files li{
    display: flex;
    align-items: center;
}
.ul_files li p{
    margin: 7px 0;
    font-weight: 600;
    font-size: 14px;
    letter-spacing: .62px;
    padding: 0 10px 0 9px;
    word-break: break-all;
}
.ul_files li button{
    background: transparent;
    border:0;
}
.wrap_review{
    padding: 15px 35px 22px 0;
    position: relative;
    border-bottom: 1px solid #ededed;
    margin-bottom: 15px;
}
.wrap_review:last-child{
    margin-bottom: 0;
    border-bottom: 0;
}
.wrap_review h5{
    font-weight: 700;
    font-size: 14px;
    letter-spacing: .8px;
    margin-bottom: 15px;
    font-family: 'Noto Sans JP', sans-serif;
    color: var(--bs-gray);
    /* color: #000; */
}
.wrap_review p{
    font-weight: 400;
    font-size: 16px;
    letter-spacing: .32px;
    word-break: break-word;
    margin: 0 20px 0 0;
    font-family: 'Noto Sans JP', sans-serif;
    color: #000;
}
.tag_label{
    display: inline-block;
    position: relative;
    padding: 5px 12px;
    border-radius: 50px;
    /* border:1px solid var(--grayWeb); */
    margin: 0 10px 5px 0;
    font-size: 12px;
    background: var(--bodyColor);
}
.wrap_review .btn_Edits_step{
    position: absolute;
    top: 30px;
    right: 0;
    width: 35px;
    height: 35px;
    border: 2px solid #585858;
    border-radius: 50%;
    outline: none;
    background: transparent;
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
}
@media screen and (max-width: 1145px) {
    .content_steps_wrap .category_list li {
        padding: 0 5px;
        margin-bottom: 12px;
    }
    .content_steps_wrap .category_list li .liDiv_list {
        padding: 9px;
    }
}

@media screen and (max-width:767px) {
    .card_wrap.p_30 {
        padding: 10px 0;
    }
    .card_wrap {
        padding: 15px 15px 10px !important;
    }
    .content_steps_wrap {
        padding: 20px;
        min-height: 0;
        margin-bottom: 95px;
    }
    .content_steps_wrap .category_list li {
        width: 50%;
    }
}

@media screen and (max-width:576px) {
    .card_wrap.p_30 {
        padding: 10px 0;
    }
    .card_wrap {
        padding: 15px 0 10px !important;
    }
    .content_steps_wrap {
        padding: 20px;
        min-height: 0;
        margin-bottom: 95px;
    }
    .content_steps_wrap .category_list{
        margin: 0;
    }
    .content_steps_wrap .category_list li {
        width: 100%;
        margin: 0;
        padding: 0;
    }
    .content_steps_wrap .category_list li .liDiv_list {
        font-size: 14px;
        color: rgba(0, 0, 0, 0.43);
        border: none;
        border-bottom: 1px solid #e2e2e2;
        border-radius: 0;
        font-weight: 600;
        margin: 0;
        padding: 15px 8px;
        width: 100%;
        float: left;
        flex-direction: column;
    }
    .content_steps_wrap .category_list li .liDiv_list.active {
        box-shadow: none;
        border-radius: 4px;
    }
    .content_steps_wrap .step_footer{
        padding: 15px;
        width: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        text-align: center;
        background: #fff;
        margin: 0;
    }
}